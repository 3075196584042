import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DrugCollectionService } from '../../Services/drug-collection.service';
import { SignalRService } from '../../Services/signalr.service';
import { LocalStorageService } from '../../Services/local-storage.service';
import { Router } from '@angular/router';
import { TrackerService } from '../../Services/tracker.service';
import { DisplayError } from '../../interfaces/display-error.interface';

@Component({
  selector: 'amzn-help-modal',
  templateUrl: './help-modal.component.html',
  styleUrls: ['./help-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HelpModalComponent implements OnInit {
  @Input()
  error: DisplayError;

  constructor(
    private activeModal: NgbActiveModal,
    private drugCollectionService: DrugCollectionService,
    private tracker: TrackerService,
    private signalRService: SignalRService,
    private localStorageService: LocalStorageService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.tracker.matomoTrackEvent('help', 'invalid', 'request help: ' + this.error.errorMessage);
    // POST request to admin
    this.postRequestHelp();

    // Subscribe to SignalR messaging
    this.signalRService.startConnection();
    this.signalRService.hubConnection.on('newMessage', (message) => {
      this.processSignalrMessage(message);
    });
  }

  processSignalrMessage(message: any): void {
    if (message !== undefined) {
      if (message.helpStatus !== undefined && message.helpStatus === 'resolved') {
        if (message.step && message.step === 'info_retake_test') {
          this.retakeTest();
        }
        this.activeModal.close();
      }
    }
  }

  retakeTest(): void {
    this.tracker.matomoTrackEvent('help', 'result : retaketest', 'redirect to open test kit help page');
    this.localStorageService.setItem('admin_result', 'retake-test');
    this.router.navigate(['admin-result']);
  }

  cancelHelpRequest(): void {
    const location = this.localStorageService.getItem('location_code');

    const body = {
      location,
      station: 'One',
    };

    this.drugCollectionService.postRequestAdminHelp(body, 'notRequested').subscribe(
      () => {
        this.activeModal.close();
      },
      (error) => {
        this.activeModal.close();

        console.error(error.errorMessage, ['get help : request admin post'], ['server']);
      },
    );
  }

  postRequestHelp(): void {
    const location = this.localStorageService.getItem('location_code');
    const requestType = this.getHelpRequestType(this.error);

    const body = {
      location,
      station: 'One',
    };

    this.drugCollectionService.postRequestAdminHelp(body, requestType).subscribe(
      () => {
        //intentionally blank
      },
      (error) => {
        console.error(error.errorMessage, ['get help : request admin post'], ['server']);
      },
    );
  }

  getHelpRequestType(error: any): string {
    let retVal = 'requested';
    if (error.errorCode === 'invalidDevice') {
      if (error.message.toLocaleLowerCase().includes('duplicate')) {
        retVal = 'duplicate';
      } else if (error.message.toLocaleLowerCase().includes('expired')) {
        retVal = 'expired';
      } else {
        retVal = 'invalidDevice';
      }
    }

    return retVal;
  }
}
