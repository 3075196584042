import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TrackerService } from '../../Services/tracker.service';

@Component({
  selector: 'amzn-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ErrorModalComponent implements OnInit {
  @Input()
  error;

  constructor(
    private activeModal: NgbActiveModal,
    private tracker: TrackerService,
  ) {}

  ngOnInit(): void {
    this.tracker.matomoTrackEvent('error popup', 'invalid', 'error popup: ' + this.error.errorMessage);
  }

  close(): void {
    this.activeModal.close();
  }
}
