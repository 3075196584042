import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { StationComponent } from './station/station.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { ReviewCandidateComponent } from './review-candidate/review-candidate.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { FadvInputComponent, FadvSignatureComponent } from '@fa-switch-sharedservices/ng-elements';
import { TranslocoRootModule } from '../transloco-root.module';

@NgModule({
  declarations: [WelcomeComponent, ReviewCandidateComponent, StationComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    FadvSignatureComponent,
    FadvInputComponent,
    TranslocoRootModule,
  ],
  exports: [WelcomeComponent, ReviewCandidateComponent],
})
export class AuthModule {}
