import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mask',
})
export class MaskPipe implements PipeTransform {
  transform(value: string, charsToUnMask: number, maskChar: string, direction: string): string {
    if (!value) {
      return value;
    }

    let maskValue = '';
    let maskDirection = 'RIGHT_TO_LEFT';
    if (direction === 'LEFT_TO_RIGHT') {
      maskDirection = direction;
    }

    if (charsToUnMask >= value.length) {
      value = this.mask(value, maskChar);
    } else {
      if (maskDirection === 'RIGHT_TO_LEFT') {
        const unMaskValue = value.substr(0, charsToUnMask);
        maskValue = value.substr(charsToUnMask, value.length);
        const maskedValue = this.mask(maskValue, maskChar);
        value = unMaskValue + maskedValue;
      } else {
        maskValue = value.substr(0, value.length - 3);
        const unMaskValue = value.substr(value.length - charsToUnMask, value.length);
        const maskedValue = this.mask(maskValue, maskChar);
        value = maskedValue + unMaskValue;
      }
    }

    return value;
  }

  mask(value: string, maskChar: string): string {
    let result = '';
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < value.length; i++) {
      result = result + maskChar;
    }

    return result;
  }
}
