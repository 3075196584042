/* eslint-disable */
export class WorkflowConfig {
  static readonly STEPS = {
    welcome: {
      link: '/welcome',
    },
    review_info: {
      link: '/review-candidate',
    },
    consent: {
      link: '/agreement/consent',
    },
    nc_consent: {
      link: '/agreement/nc-consent',
    },
    info_open_pouch: {
      link: '/instruction/open-test-kit-help',
    },
    info_test_id_capture: {
      link: '/testkit/test-kit-id-info',
    },
    info_collect_saliva: {
      link: '/instruction/process-help',
    },
    info_wait_indicator: {
      link: '/instruction/collect-sample-step2',
    },
    info_insert_swab: {
      link: '/instruction/complete-help',
    },
    second_info_collect_saliva: {
      link: '/instruction/process-help;secondTimer=true',
    },
    wait_for_test_results: {
      link: '/instruction/result-wait-help',
    },
    secondary_wait_for_test_results: {
      link: '/instruction/result-wait-sec-help',
    },
    see_test_results: {
      link: '/instruction/see-results-help',
    },
    info_capture_image: {
      link: '/instruction/photo-help',
    },
    capture_image: {
      link: '/capture-photo/live-capture',
    },
    confirm_image: {
      link: '/capture-photo/capture-confirmation',
    },
    image_processing: {
      link: '/admin-result/wait',
    },
    wait_for_admin: {
      link: '/admin-result/wait',
    },
    info_pool_saliva: {
      link: '/instruction/introduction-help',
    },
    shipit_package_testkit_help: {
      link: '/admin-result/package-your-test',
    },
    shipit_photo_rules_help: {
      link: '/admin-result/photo-rules',
    },
    info_shipit: {
      link: '/admin-result/shipit-help',
    },
    thankyou: {
      link: '/thankyou',
    },
    info_cleanup: {
      link: '/admin-result/cleanup-help',
    },
    info_retake_test: {
      link: '/admin-result/retake-test',
    },
    mro_advance_notification: {
      link: '/admin-result/mro-advance-notification',
    },
    upload_image: {
      link: '/capture-photo/upload-image',
    },
    info_oraleze_open_pouch: {
      link: '/oraleze/open-pouch-help',
    },
    info_oraleze_sample_collection: {
      link: '/oraleze/sample-collection-start',
    },
    info_oraleze_insert_collection_wand: {
      link: '/oraleze/insert-collection-wand',
    },
    info_oraleze_close_cap: {
      link: '/oraleze/close-cap',
    },
    shipit_oraleze_package_testkit_help: {
      link: '/oraleze/seal-device',
    },
    shipit_oraleze_photo_rules_help: {
      link: '/oraleze/photo-instruction',
    },
    shipit_photo_rules_initials_help: {
      link: '/admin-result/photo-rules-initials',
    },
    shipit_oraleze_photo_rules_initials_help: {
      link: '/admin-result/photo-rules-initials',
    },
    info_retake_photo: {
      link: '/instruction/photo-help',
    },
    info_open_alcohol_pouch: {
      link: '/alcohol/open-alcohol-pouch',
    },
    alcohol_wait: {
      link: '/alcohol/alcohol-wait',
    },
    place_alcohol_strip: {
      link: '/alcohol/place-alcohol-strip-on-napkin',
    },

    proceed_to_alcohol_test: {
      link: '/instruction/result-wait-help',
    },

    pick_up_oraltox_device: {
      link: '/instruction/see-results-help',
    },

    check_device_sku: {
      link: '/instruction/review-package-before-opening',
    },

    review_alcohol_strip_before_opening: {
      link: '/instruction/review-alcohol-strip-before-opening',
    },

    // wait_for_admin
    // retake_test
    // info_retake_photo
    // info_shipti
    // info cleanup
  };
}

export interface Link {
  link: string;
}
