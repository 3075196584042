export const environment = {
  production: false,
  buildVersion: 'dev',
  buildDate: new Date().toISOString(),
  matomoConfig: {
    scriptUrl: '//metrics-test.fadv.com/matomo.js',
    trackers: [
      {
        trackerUrl: 'https://metrics-test.fadv.com/matomo.php',
        siteId: 5,
      },
    ],
    skipTrackingInitialPageView: false,
    routeTracking: {
      enable: true,
    },
    trackLinks: true,
  },
};
