import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, pluck, Subscription } from 'rxjs';
import { Disclosure } from '../../interfaces/disclosure.interface';
import { AudioService } from '../../Services/audio.service';
import { DrugCollectionService } from '../../Services/drug-collection.service';
import { LocalStorageService } from '../../Services/local-storage.service';
import { TrackerService } from '../../Services/tracker.service';
import { DisclosureDeclineModalComponent } from '../disclosure-decline-modal/disclosure-decline-modal.component';
import { AgreeClickEvent } from './agree-click-event.interface';
import { TranslocoService } from '@jsverse/transloco';
@Component({
  selector: 'amzn-dynamic-agreement',
  templateUrl: './dynamic-agreement.component.html',
  styleUrls: ['./dynamic-agreement.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DynamicAgreementComponent implements OnInit, OnDestroy {
  @Input()
  showEmailCheckbox = false;

  @Input()
  shouldEmailResult = false;

  @Output()
  agreeClickEvent: EventEmitter<AgreeClickEvent> = new EventEmitter();

  @Input()
  disclosureName: string;

  @Input()
  nextRoute: string;

  @Input()
  backRoute: string;

  @Input()
  step: string;

  @Input()
  audioFile?: string;

  disclosure: Disclosure = {
    title: '...',
    content: '...',
    id: '',
    name: '',
    version: 0,
    language: '',
  };
  disclosureDeclineModalRef: NgbModalRef;
  translocoSub1: Subscription;
  sub2: Subscription;
  sub3: Subscription;

  constructor(
    private drugCollectionService: DrugCollectionService,
    private localStorageService: LocalStorageService,
    private tracker: TrackerService,
    private router: Router,
    private ngbModal: NgbModal,
    private audioService: AudioService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.tracker.matomoTrackPageView(this.disclosureName, window.location.hash.substr(1));
    const activeLangauge = this.translocoService.getActiveLang();
    this.drugCollectionService.getDisclosure(this.disclosureName, activeLangauge).subscribe((disclosure) => {
      this.disclosure = disclosure;
    });
    this.drugCollectionService.postStatusCall(this.step).subscribe(() => {});
    if (this.audioFile) {
      this.audioService.emitAudioFileName(this.audioFile);
    }
    this.translocoSub1 = this.translocoService.events$
      .pipe(
        filter((e) => e.type === 'langChanged'),
        pluck('payload'),
      )
      .subscribe(({ langName }) => {
        this.drugCollectionService.getDisclosure(this.disclosureName, langName).subscribe((disclosure) => {
          this.disclosure = disclosure;
        });
      });
  }

  agree(): void {
    this.tracker.matomoTrackEvent(this.disclosureName, 'clicked', 'agreed');
    this.drugCollectionService.processDisclosure(this.disclosure, 'agree').subscribe(() => {
      this.router.navigateByUrl(this.nextRoute);
    });
    this.agreeClickEvent.emit({
      showEmailCheckbox: this.showEmailCheckbox,
      shouldEmailResult: this.shouldEmailResult,
    });
  }

  decline(): void {
    this.tracker.matomoTrackEvent(this.disclosureName, 'clicked', 'declined');
    this.openDisclosureDeclineModal();
  }

  back(): void {
    this.tracker.matomoTrackEvent(this.disclosureName, 'clicked', 'back');
    this.router.navigateByUrl(this.backRoute);
  }

  openDisclosureDeclineModal(): void {
    this.disclosureDeclineModalRef = this.ngbModal.open(DisclosureDeclineModalComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: true,
      centered: true,
      windowClass: 'customDisclosureDeclineModal',
      ariaLabelledBy: 'disclosureDeclineModal',
    });
    this.sub2 = this.disclosureDeclineModalRef.componentInstance.finalizeDeclineEvent.subscribe(() => {
      this.handleConfirmedDeclineEvent();
    });
    this.sub3 = this.disclosureDeclineModalRef.dismissed.subscribe(() => {
      this.handleModalDismissEvent();
    });
  }

  handleModalDismissEvent(): void {
    this.tracker.matomoTrackEvent(this.disclosureName + ' decline-modal', 'clicked', 'Decline Cancelled');
    this.unsubscribeAll();
  }

  handleConfirmedDeclineEvent(): void {
    this.tracker.matomoTrackEvent(this.disclosureName + ' decline-modal', 'clicked', 'Decline Confirmed');
    this.drugCollectionService.processDisclosure(this.disclosure, 'decline').subscribe(() => {
      this.localStorageService.clear();
      this.router.navigateByUrl('welcome');
    });
  }

  setEmailResult(event): void {
    this.shouldEmailResult = event.target.checked;
  }

  unsubscribeAll(): void {
    if (this.sub2) {
      this.sub2.unsubscribe();
    }
    if (this.sub3) {
      this.sub3.unsubscribe();
    }
    if (this.translocoSub1) {
      this.translocoSub1.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }
}
