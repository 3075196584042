import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DrugCollectionService } from '../../shared/Services/drug-collection.service';
import { EventBusService } from '../../shared/Services/event-bus.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LocalStorageService } from '../../shared/Services/local-storage.service';
import { FaqService } from '../../shared/Services/faq.service';
import { AudioService } from '../../shared/Services/audio.service';
import { NgxMaskModule } from 'ngx-mask';
import { TrackerService } from '../../shared/Services/tracker.service';
import { TranslocoService } from '@jsverse/transloco';
declare let jQuery: any;
@Component({
  selector: 'amzn-review-candidate',
  templateUrl: './review-candidate.component.html',
  styleUrls: ['./review-candidate.component.scss'],
})
export class ReviewCandidateComponent implements OnInit, AfterViewInit {
  candidateForm: UntypedFormGroup;
  emailAddress: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.email]);
  phoneNumber: UntypedFormControl = new UntypedFormControl('', [Validators.required]);

  addressLine1: string;
  addressLine2: string;
  firstName: string;
  lastName: string;
  city: string;
  state: string;
  zip: string;
  errorMessage: string;
  isEditScenario = false;
  contactValidationErrors = false;
  editingContacts = false;
  nextClicked = false;
  backendEmailValid = true;
  backendPhoneValid = true;

  constructor(
    private router: Router,
    private drugCollectionService: DrugCollectionService,
    private eventBus: EventBusService,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private faqService: FaqService,
    private audioService: AudioService,
    private tracker: TrackerService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    NgxMaskModule.forRoot();
    this.tracker.matomoTrackPageView('Review Info', window.location.hash.substr(1));
    this.faqService.setFaqHtml('');
    this.eventBus.helpContentAnnounced({
      // eslint-disable-next-line
      help_content: this.translocoService.translate('reviewCandidateComponent.helpContent'),
    });
    this.audioService.emitAudioFileName('review-candidate');
    this.firstName = this.localStorageService.getItem('first_name');
    this.lastName = this.localStorageService.getItem('last_name');
    this.addressLine1 = this.localStorageService.getItem('address_line_1');
    this.addressLine2 = this.localStorageService.getItem('address_line_2');
    this.city = this.localStorageService.getItem('city');
    this.state = this.localStorageService.getItem('state');
    this.zip = this.localStorageService.getItem('zip');
    this.emailAddress.setValue(this.localStorageService.getItem('emailAddress'));
    this.phoneNumber.setValue(this.formatPhoneNo(this.localStorageService.getItem('phone_number')));
    this.updateStep();
    this.candidateForm = this.formBuilder.group({
      phoneNumber: this.phoneNumber,
      emailAddress: this.emailAddress,
    });

    if (this.localStorageService.getItem('edit_contact_information_scenario') === 'true') {
      this.isEditScenario = true;
      this.editContactInfo();
    }
    this.editingContacts = false; // seems to reset to true if trying a few times/refreshing
  }

  ngAfterViewInit(): void {
    this.tracker.matomoTrackEvent('auth', 'visited', 'candidate info loaded');
  }

  goToNextStage(): void {
    this.tracker.matomoTrackEvent('auth', 'clicked', 'Clicked Next = Contact informtaion updated');
    this.localStorageService.setItem('edit_contact_information_scenario', 'false');

    if (this.isEditScenario) {
      // if we've edited, try local validation first and return if errors
      if (!this.nextClicked) {
        this.nextClicked = true;
        if (!this.emailAddress.valid || !this.phoneNumber.valid) {
          return;
        } // do local validation on first next
      }
      this.nextClicked = true;
      this.showPleaseWait(true);
      this.updateContactInformationPost(this.phoneNumber.value, this.emailAddress.value);
    } else {
      this.router.navigateByUrl('/agreement/e-signature-consent');
    }
  }

  formValid(formControl: UntypedFormControl): boolean {
    if (formControl.touched && !formControl.valid) {
      return false;
    }
    return true;
  }
  contactInfoValid(): boolean {
    return this.emailAddress.valid && this.phoneNumber.valid;
  }
  emailError(): string {
    // want to replace with server message
    return 'Please enter a valid email address';
  }
  emailValid(): boolean {
    if (!this.nextClicked) {
      return true;
    } // don't do anything until they've clicked next
    return this.emailAddress.valid && this.backendEmailValid;
  }
  phoneValid(): boolean {
    if (!this.nextClicked) {
      return true;
    } // don't do anything until they've clicked next
    return this.phoneNumber.valid && this.backendPhoneValid;
  }
  editContactInfo(): void {
    this.isEditScenario = true;
    this.editingContacts = true;
    this.tracker.matomoTrackEvent('auth', 'clicked', 'edit contact info');
    this.localStorageService.setItem('edit_contact_information_scenario', 'true');
    this.audioService.emitAudioFileName('edit-candidate');
  }

  showPleaseWait(flag: boolean): void {
    if (flag) {
      jQuery('#please-wait-modal').modal('show');
    } else {
      jQuery('#please-wait-modal').modal('hide');
    }
  }

  updateStep(): void {
    this.drugCollectionService.postStatusCall('review_info').subscribe({
      error: (error) => {
        console.error(error.errorMessage, ['review candidate : update step call'], ['server']);
      },
    });
  }

  cancel(): void {
    this.isEditScenario = false;
    this.editingContacts = false;
    this.emailAddress.setValue(this.localStorageService.getItem('emailAddress'));
    this.phoneNumber.setValue(this.formatPhoneNo(this.localStorageService.getItem('phone_number')));
    this.tracker.matomoTrackEvent('auth', 'clicked', 'cancel button');
    this.audioService.emitAudioFileName('review-candidate');
  }

  formatPhoneNo(value: string): string {
    if (!value) {
      return value;
    }
    let val = value.replace(/\D/g, '');
    let newVal = '';

    if (val.length > 3 && val.length < 7) {
      newVal = '(' + val.substr(0, 3) + ') ';
      val = val.substr(3);
    }
    if (val.length > 6) {
      newVal = '(' + val.substr(0, 3) + ') ';
      newVal += val.substr(3, 3) + ' - ';
      val = val.substr(6, 4);
    }
    newVal += val;
    return newVal;
  }
  formatEmailAddress(value: string): string {
    return value;
  }
  getEmailError(): string {
    if (this.contactValidationErrors) {
      return 'backend email error';
    }
    return 'Please enter a valid email address';
  }
  validatePhoneNo(): void {
    this.phoneNumber.setValue(this.formatPhoneNo(this.phoneNumber.value));
  }
  handleError(error: any) {
    this.contactValidationErrors = true;
    const errorDetail = error?.error?.errors;
    if (errorDetail.length > 1) {
      this.backendEmailValid = false;
      this.backendPhoneValid = false;
    } else {
      const propertyName = errorDetail[0].propertyName;
      if (propertyName.startsWith('Phone')) {
        this.backendEmailValid = true;
        this.backendPhoneValid = false;
      } else {
        this.backendEmailValid = false;
        this.backendPhoneValid = true;
      }
    }
  }
  updateContactInformationPost(newPhoneNo: string, newEmail: string): void {
    const body = {
      phoneNumber: newPhoneNo,
      emailAddress: newEmail,
    };

    this.drugCollectionService.updateContactInformation(body).subscribe({
      next: () => {
        this.showPleaseWait(false);
        this.localStorageService.setItem('phone_number', newPhoneNo);
        this.localStorageService.setItem('emailAddress', newEmail);
        this.router.navigateByUrl('/agreement/e-signature-consent');
      },
      error: (error) => {
        // add conditional logic here
        this.errorMessage = error.errorMessage;
        this.showPleaseWait(false);
        console.error(error);
        console.error(error.errorMessage, ['review candidate : contact information update call'], ['server']);
        this.handleError(error);
      },
    });
  }
}
