import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StationComponent } from './auth/station/station.component';
import { WelcomeComponent } from './auth/welcome/welcome.component';
import { ReviewCandidateComponent } from './auth/review-candidate/review-candidate.component';
import { ThankyouComponent } from './pages/thankyou/thankyou.component';
import { WelcomeDataResolverService } from './auth/welcome/welcome-data-resolver.service';

export const routes: Routes = [
  {
    path: '',
    component: WelcomeComponent,
    resolve: {
      userData: WelcomeDataResolverService,
    },
    title: "Let's Get Started By Validating Your Information",
  },
  {
    path: 'welcome',
    component: WelcomeComponent,
    resolve: {
      userData: WelcomeDataResolverService,
    },
    title: "Let's Get Started By Validating Your Information",
  },
  {
    path: 'station',
    component: StationComponent,
    title: 'Enter Booth Number',
  },
  {
    path: 'review-candidate',
    component: ReviewCandidateComponent,
    title: 'Review your Information',
  },
  {
    path: '',
    children: [
      {
        path: 'instruction',
        loadChildren: () => import('./pages/instruction/instruction.module').then((mod) => mod.InstructionModule),
      },
      {
        path: 'agreement',
        loadChildren: () => import('./pages/agreement/agreement.module').then((mod) => mod.AgreementModule),
      },
      {
        path: 'capture-photo',
        loadChildren: () => import('./pages/camera/camera.module').then((mod) => mod.CameraModule),
      },
      {
        path: 'debug',
        loadChildren: () => import('./pages/debug/debug.module').then((mod) => mod.DebugModule),
      },
      {
        path: 'admin-result',
        loadChildren: () => import('./pages/result/result.module').then((mod) => mod.ResultModule),
      },
      {
        path: 'testkit',
        loadChildren: () => import('./pages/testkit/testkit.module').then((mod) => mod.TestkitModule),
      },
      {
        path: 'oraleze',
        loadChildren: () => import('./pages/oraleze/oraleze.module').then((mod) => mod.OralezeModule),
      },
      {
        path: 'alcohol',
        loadChildren: () => import('./pages/alcohol/alcohol.module').then((mod) => mod.AlcoholModule),
      },
    ],
  },
  {
    path: 'thankyou',
    component: ThankyouComponent,
    title: 'Thank You',
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
