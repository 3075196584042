/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
  providedIn: 'root',
})
export class AudioService {
  playAudio = new Subject<boolean>();
  welcomePlay = new Subject<boolean>();
  audioFileName = new Subject<string>();
  audioTrancriptFileName = new Subject<string>();
  translocoSub1: Subscription;
  currentComponent: string;

  constructor(private translocoService: TranslocoService) {}

  private componentToAudio = {
    'welcome-alias-en-US': 'assets/audio/audioFiles/en-US/Lets_get_started_Alias.m4a',
    'welcome-alias-es-419': 'assets/audio/audioFiles/es-419/Empecemos_Alias.m4a',
    'welcome-phone-number-en-US': 'assets/audio/audioFiles/en-US/Lets_get_started_phone_number.m4a',
    'welcome-phone-number-es-419': 'assets/audio/audioFiles/es-419/Empecemos_Número_de_Teléfono.m4a',
    'enter-booth-en-US': 'assets/audio/audioFiles/en-US/enter_Booth_Number.m4a',
    'enter-booth-es-419': 'assets/audio/audioFiles/es-419/Introduzca_Número_de_Cabina.m4a',
    'review-candidate-en-US': 'assets/audio/audioFiles/en-US/preferred_contact_info.m4a',
    'review-candidate-es-419': 'assets/audio/audioFiles/es-419/Information_de_Contacto_Preferida.m4a',
    'edit-candidate-en-US': 'assets/audio/audioFiles/en-US/contact_info_change.m4a',
    'edit-candidate-es-419': 'assets/audio/audioFiles/es-419/Cambio_de_Información_de_Contacto.m4a',
    'consent-en-US': 'assets/audio/audioFiles/en-US/consent.mp3',
    'consent-es-419': 'assets/audio/audioFiles/es-419/Consentimiento.m4a',
    'esignature-consent-en-US': 'assets/audio/audioFiles/en-US/Amazon_e_signature_consent.m4a',
    'esignature-consent-es-419': 'assets/audio/audioFiles/es-419/Consentimiento_de_Firma_Electronica_de_Amazon.m4a',
    'amazon-drug-test-policy-en-US': 'assets/audio/audioFiles/en-US/Amazon_drug_test_policy.m4a',
    'amazon-drug-test-policy-es-419': 'assets/audio/audioFiles/es-419/Política_de_Prueba_de_Drogas_de_Amazon.m4a',
    'fair-credit-reporting-act-en-US': 'assets/audio/audioFiles/en-US/Amazon_FCRA_disclosure_and_authorization.m4a',
    'fair-credit-reporting-act-es-419': 'assets/audio/audioFiles/es-419/Divulgación_y_Autorización_FCRA_de_Amazon.m4a',
    'california-consumer-reports-en-US': 'assets/audio/audioFiles/en-US/Amazon_CA_disclosure_consent.m4a',
    'california-consumer-reports-es-419':
      'assets/audio/audioFiles/es-419/Consentimiento_de_Divulgación_de_CA_de_Amazon.m4a',
    'additional-disclosures-and-acknowledgements-en-US':
      'assets/audio/audioFiles/en-US/Amazon_additional_disclosures_and_acknowledgements.m4a',
    'additional-disclosures-and-acknowledgements-es-419':
      'assets/audio/audioFiles/es-419/Divulgaciones_y_Reconocimientos_Adicionales_de_Amazon.m4a',
    'nc-consent-en-US': 'assets/audio/audioFiles/en-US/nc_consent.mp3',
    'nc-consent-es-419': 'assets/audio/audioFiles/es-419/Consentimento_NC.m4a',
    'open-testkit-help-en-US': 'assets/audio/audioFiles/en-US/open_pouch.mp3',
    'open-testkit-help-es-419': 'assets/audio/audioFiles/es-419/Abrir_Bolsa.m4a',
    'introduction-help-en-US': 'assets/audio/audioFiles/en-US/use_saliva.mp3',
    'introduction-help-es-419': 'assets/audio/audioFiles/es-419/Usar_Saliva.m4a',
    'process-help-en-US': 'assets/audio/audioFiles/en-US/collect_sample.mp3',
    'process-help-es-419': 'assets/audio/audioFiles/es-419/Recoger_Muestra.m4a',
    'collect-sample-step2-en-US': 'assets/audio/audioFiles/en-US/mirror_script.mp3',
    'collect-sample-step2-es-419': 'assets/audio/audioFiles/es-419/Guión_del_Espejo.m4a',
    'complete-help-en-US': 'assets/audio/audioFiles/en-US/insert_swab.mp3',
    'complete-help-es-419': 'assets/audio/audioFiles/es-419/Insertar_Hisopo.m4a',
    'result-wait-help-en-US': 'assets/audio/audioFiles/en-US/wait_screen_one.mp3',
    'result-wait-help-es-419': 'assets/audio/audioFiles/es-419/Pantalha_de_Espera_Uno.m4a',
    'amzn-see-results-help-en-US': 'assets/audio/audioFiles/en-US/results_screen.mp3',
    'amzn-see-results-help-es-419': 'assets/audio/audioFiles/es-419/Pantalla_de_Resultados.m4a',
    'amzn-result-wait-sec-help-en-US': 'assets/audio/audioFiles/en-US/wait_screen_two.mp3',
    'amzn-result-wait-sec-help-es-419': 'assets/audio/audioFiles/es-419/Pantalha_de_Espera_Dos.m4a',
    'photo-help-en-US': 'assets/audio/audioFiles/en-US/photo_rules.m4a',
    'photo-help-es-419': 'assets/audio/audioFiles/es-419/Reglas_de_Foto.m4a',
    'capture-confirmation-en-US': 'assets/audio/audioFiles/en-US/review_photo.m4a',
    'capture-confirmation-es-419': 'assets/audio/audioFiles/es-419/Revisar_Foto.m4a',
    'test-kit-id-info-en-US': 'assets/audio/audioFiles/en-US/device_information_page.m4a',
    'test-kit-id-info-es-419': 'assets/audio/audioFiles/es-419/Página_de_Información_del_Dispositivo.m4a',
    'retake-test-en-US': 'assets/audio/audioFiles/en-US/start_over.mp3',
    'retake-test-es-419': 'assets/audio/audioFiles/es-419/Reiniciar.m4a',
    'cleanup-help-en-US': 'assets/audio/audioFiles/en-US/clean_your_station.mp3',
    'cleanup-help-es-419': 'assets/audio/audioFiles/es-419/Limpiar_su_Estación.m4a',
    'cleanup-help-post-en-US': 'assets/audio/audioFiles/en-US/clean_up_post.m4a',
    'cleanup-help-post-es-419': 'assets/audio/audioFiles/es-419/Limpieza_Posterior.m4a',
    'open-alcohol-pouch-en-US': 'assets/audio/audioFiles/en-US/open-alcohol-pouch.m4a',
    'open-alcohol-pouch-es-419': 'assets/audio/audioFiles/es-419/Abrir_Bolsa_de_Alcohol.m4a',
    'alcohol-wait-en-US': 'assets/audio/audioFiles/en-US/alcohol-wait.m4a',
    'alcohol-wait-es-419': 'assets/audio/audioFiles/es-419/Espera_de_Alcohol.m4a',
    'place-alcohol-strip-on-napkin-en-US': 'assets/audio/audioFiles/en-US/place-alcohol-strip-on-napkin.m4a',
    'place-alcohol-strip-on-napkin-es-419': 'assets/audio/audioFiles/es-419/Colocar_Tira_de_Alcohol_en_Servilleta.m4a',
    'review-alcohol-strip-en-US': 'assets/audio/audioFiles/en-US/review-alcohol-strip.m4a',
    'review-alcohol-strip-es-419': 'assets/audio/audioFiles/es-419/Revisar_Tira_de_Alcohol.m4a',
    'review-package-post-en-US': 'assets/audio/audioFiles/en-US/review-package-post.m4a',
    'review-package-post-es-419': 'assets/audio/audioFiles/es-419/Revisar_Paquete-Posterior.m4a',
    'review-package-pre-en-US': 'assets/audio/audioFiles/en-US/review-package-pre.m4a',
    'review-package-pre-es-419': 'assets/audio/audioFiles/es-419/Revisar_Paquete-Previo.m4a',
    'mro-advance-notification-en-US': 'assets/audio/audioFiles/en-US/mro_advance_notification.m4a',
    'mro-advance-notification-es-419': 'assets/audio/audioFiles/es-419/Notificación_de_Avance_del_MRO.m4a',
  };

  private componentToTranscript = {
    'welcome-alias-en-US': 'assets/audio/audioTranscripts/en-US/Lets_get_started_Alias.txt',
    'welcome-alias-es-419': 'assets/audio/audioTranscripts/es-419/Empecemos_Alias.txt',
    'welcome-phone-number-en-US': 'assets/audio/audioTranscripts/en-US/Lets_get_started_phone_number.txt',
    'welcome-phone-number-es-419': 'assets/audio/audioTranscripts/es-419/Empecemos_Número_de_Teléfono.txt',
    'enter-booth-en-US': 'assets/audio/audioTranscripts/en-US/enter_Booth_Number.txt',
    'enter-booth-es-419': 'assets/audio/audioTranscripts/es-419/Introduzca_Número_de_Cabina.txt',
    'review-candidate-en-US': 'assets/audio/audioTranscripts/en-US/preferred_contact_info.txt',
    'review-candidate-es-419': 'assets/audio/audioTranscripts/es-419/Information_de_Contacto_Preferida.txt',
    'edit-candidate-en-US': 'assets/audio/audioTranscripts/en-US/contact_info_change.txt',
    'edit-candidate-es-419': 'assets/audio/audioTranscripts/es-419/Cambio_de_Información_de_Contacto.txt',
    'consent-en-US': 'assets/audio/audioTranscripts/en-US/consent.txt',
    'consent-es-419': 'assets/audio/audioTranscripts/es-419/Consentimiento.txt',
    'esignature-consent-en-US': 'assets/audio/audioTranscripts/en-US/Amazon_e_signature_consent.txt',
    'esignature-consent-es-419':
      'assets/audio/audioTranscripts/es-419/Consentimiento_de_Firma_Electronica_de_Amazon.txt',
    'amazon-drug-test-policy-en-US': 'assets/audio/audioTranscripts/en-US/Amazon_drug_test_policy.txt',
    'amazon-drug-test-policy-es-419': 'assets/audio/audioTranscripts/es-419/Política_de_Prueba_de_Drogas_de_Amazon.txt',
    'fair-credit-reporting-act-en-US':
      'assets/audio/audioTranscripts/en-US/Amazon_FCRA_disclosure_and_authorization.txt',
    'fair-credit-reporting-act-es-419':
      'assets/audio/audioTranscripts/es-419/Divulgación_y_Autorización_FCRA_de_Amazon.txt',
    'california-consumer-reports-en-US': 'assets/audio/audioTranscripts/en-US/Amazon_CA_disclosure_consent.txt',
    'california-consumer-reports-es-419':
      'assets/audio/audioTranscripts/es-419/Consentimiento_de_Divulgación_de_CA_de_Amazon.txt',
    'additional-disclosures-and-acknowledgements-en-US':
      'assets/audio/audioTranscripts/en-US/Amazon_additional_disclosures_and_acknowledgements.txt',
    'additional-disclosures-and-acknowledgements-es-419':
      'assets/audio/audioTranscripts/es-419/Divulgaciones_y_Reconocimientos_Adicionales_de_Amazon.txt',
    'nc-consent-en-US': 'assets/audio/audioTranscripts/en-US/nc_consent.txt',
    'nc-consent-es-419': 'assets/audio/audioTranscripts/es-419/Consentimento_NC.txt',
    'open-testkit-help-en-US': 'assets/audio/audioTranscripts/en-US/open_pouch.txt',
    'open-testkit-help-es-419': 'assets/audio/audioTranscripts/es-419/Abrir_Bolsa.txt',
    'introduction-help-en-US': 'assets/audio/audioTranscripts/en-US/use_saliva.txt',
    'introduction-help-es-419': 'assets/audio/audioTranscripts/es-419/Usar_Saliva.txt',
    'process-help-en-US': 'assets/audio/audioTranscripts/en-US/collect_sample.txt',
    'process-help-es-419': 'assets/audio/audioTranscripts/es-419/Recoger_Muestra.txt',
    'collect-sample-step2-en-US': 'assets/audio/audioTranscripts/en-US/mirror_script.txt',
    'collect-sample-step2-es-419': 'assets/audio/audioTranscripts/es-419/Guión_del_Espejo.txt',
    'complete-help-en-US': 'assets/audio/audioTranscripts/en-US/insert_swab.txt',
    'complete-help-es-419': 'assets/audio/audioTranscripts/es-419/Insertar_Hisopo.txt',
    'result-wait-help-en-US': 'assets/audio/audioTranscripts/en-US/wait_screen_one.txt',
    'result-wait-help-es-419': 'assets/audio/audioTranscripts/es-419/Pantalha_de_Espera_Uno.txt',
    'amzn-see-results-help-en-US': 'assets/audio/audioTranscripts/en-US/results_screen.txt',
    'amzn-see-results-help-es-419': 'assets/audio/audioTranscripts/es-419/Pantalla_de_Resultados.txt',
    'amzn-result-wait-sec-help-en-US': 'assets/audio/audioTranscripts/en-US/wait_screen_two.txt',
    'amzn-result-wait-sec-help-es-419': 'assets/audio/audioTranscripts/es-419/Pantalha_de_Espera_Dos.txt',
    'photo-help-en-US': 'assets/audio/audioTranscripts/en-US/photo_rules.txt',
    'photo-help-es-419': 'assets/audio/audioTranscripts/es-419/Reglas_de_Foto.txt',
    'capture-confirmation-en-US': 'assets/audio/audioTranscripts/en-US/review_photo.txt',
    'capture-confirmation-es-419': 'assets/audio/audioTranscripts/es-419/Revisar_Foto.txt',
    'test-kit-id-info-en-US': 'assets/audio/audioTranscripts/en-US/device_information_page.txt',
    'test-kit-id-info-es-419': 'assets/audio/audioTranscripts/es-419/Página_de_Información_del_Dispositivo.txt',
    'retake-test-en-US': 'assets/audio/audioTranscripts/en-US/start_over.txt',
    'retake-test-es-419': 'assets/audio/audioTranscripts/es-419/Reiniciar.txt',
    'cleanup-help-en-US': 'assets/audio/audioTranscripts/en-US/clean_your_station.txt',
    'cleanup-help-es-419': 'assets/audio/audioTranscripts/es-419/Limpiar_su_Estación.txt',
    'cleanup-help-post-en-US': 'assets/audio/audioTranscripts/en-US/clean_up_post.txt',
    'cleanup-help-post-es-419': 'assets/audio/audioTranscripts/es-419/Limpieza_Posterior.txt',
    'open-alcohol-pouch-en-US': 'assets/audio/audioTranscripts/en-US/open-alcohol-pouch.txt',
    'open-alcohol-pouch-es-419': 'assets/audio/audioTranscripts/es-419/Abrir_Bolsa_de_Alcohol.txt',
    'alcohol-wait-en-US': 'assets/audio/audioTranscripts/en-US/alcohol-wait.txt',
    'alcohol-wait-es-419': 'assets/audio/audioTranscripts/es-419/Espera_de_Alcohol.txt',
    'place-alcohol-strip-on-napkin-en-US': 'assets/audio/audioTranscripts/en-US/place-alcohol-strip-on-napkin.txt',
    'place-alcohol-strip-on-napkin-es-419':
      'assets/audio/audioTranscripts/es-419/Colocar_Tira_de_Alcohol_en_Servilleta.txt',
    'review-alcohol-strip-en-US': 'assets/audio/audioTranscripts/en-US/review-alcohol-strip.txt',
    'review-alcohol-strip-es-419': 'assets/audio/audioTranscripts/es-419/Revisar_Tira_de_Alcohol.txt',
    'review-package-post-en-US': 'assets/audio/audioTranscripts/en-US/review-package-post.txt',
    'review-package-post-es-419': 'assets/audio/audioTranscripts/es-419/Revisar_Paquete-Posterior.txt',
    'review-package-pre-en-US': 'assets/audio/audioTranscripts/en-US/review-package-pre.txt',
    'review-package-pre-es-419': 'assets/audio/audioTranscripts/es-419/Revisar_Paquete-Previo.txt',
    'mro-advance-notification-en-US': 'assets/audio/audioTranscripts/en-US/mro_advance_notification.txt',
    'mro-advance-notification-es-419': 'assets/audio/audioTranscripts/es-419/Notificación_de_Avance_del_MRO.txt',
  };

  emitAudioFileName(componentName: string): void {
    this.currentComponent = componentName;
    let activeLang = this.translocoService.getActiveLang();
    if (!activeLang) {
      activeLang = 'en-US';
    }
    this.audioFileName.next(
      this.componentToAudio[`${componentName}-${activeLang}`]
        ? this.componentToAudio[`${componentName}-${activeLang}`]
        : '',
    );
    this.audioTrancriptFileName.next(
      this.componentToTranscript[`${componentName}-${activeLang}`]
        ? this.componentToTranscript[`${componentName}-${activeLang}`]
        : '',
    );
  }

  switchAudioTranslations() {
    if (this.currentComponent) {
      this.emitAudioFileName(this.currentComponent);
    }
  }

  emitPlayAudio(playAudio: boolean): void {
    this.playAudio.next(playAudio);
  }

  emitPlayForWelcome(): void {
    this.welcomePlay.next(true);
  }
}
