import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { AuthModule } from './auth/auth.module';
import { AppRoutingModule, routes } from './app-routing.module';
import { MatomoModule } from 'ngx-matomo';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MaskPipe } from './shared/pipes/mask.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { NgxImageCompressService } from 'ngx-image-compress';
import { OralToxLabelReaderModule } from '@fadv/oral-tox-label-reader';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { SharedModule } from './shared/shared.module';
import { ThankyouComponent } from './pages/thankyou/thankyou.component';
import { ErrorHandlerService } from './shared/Services/error-handler.service';
import { environment } from '../environments/environment';
import { FadvStylesService } from '@fa-switch-sharedservices/ng-elements';
import { I18N_PROVIDERS } from './app.translations';
import { I18NextModule } from 'angular-i18next';
import { TranslocoRootModule } from './transloco-root.module';
import { TranslocoTestingModule } from '@jsverse/transloco';

const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};
@NgModule({
  declarations: [AppComponent, MaskPipe, ThankyouComponent],
  imports: [
    AppRoutingModule,
    AuthModule,
    I18NextModule,
    I18NextModule.forRoot(),
    BackButtonDisableModule.forRoot(),
    BrowserModule,
    HttpClientModule,
    MatomoModule.forRoot(environment.matomoConfig),
    NgbModule,
    NgxMaskModule.forRoot(maskConfigFunction),
    OralToxLabelReaderModule,
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
    SharedModule,
    TranslocoRootModule,
    TranslocoTestingModule,
  ],
  providers: [
    ...I18N_PROVIDERS, //<-- app translations for fadvcomponents
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerService, multi: true },
    MaskPipe,
    NgxImageCompressService,
    FadvStylesService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}
