<div *transloco="let t; prefix: 'helpModalComponent'" class="modal-dialog">
  <div id="helpRequestedModal" hidden>{{ t('helpModalAriaLabel') }}</div>
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title">{{ t('helpModalTitle') }}</h2>
    </div>
    <div class="modal-body text-center">
      <p>{{ t('helpModalInformation') }}</p>
      <p>{{ error.message }}</p>
    </div>
    <div class="modal-footer">
      <div class="row btnAlign">
        <div class="col-12 mx-auto">
          <button type="button" class="btn button btnActive" (click)="cancelHelpRequest()">{{ t('cancelRequestButton') }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
