import { Component, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'amzn-disclosure-decline-modal',
  templateUrl: './disclosure-decline-modal.component.html',
  styleUrls: ['./disclosure-decline-modal.component.scss'],
})
export class DisclosureDeclineModalComponent {
  @Output() finalizeDeclineEvent: EventEmitter<any> = new EventEmitter();

  constructor(public modal: NgbActiveModal) {}

  back(): void {
    this.modal.dismiss('Canceled decline');
  }

  decline(): void {
    this.modal.close();
    this.finalizeDeclineEvent.emit();
  }
}
