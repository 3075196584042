import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { EventBusService } from '../../Services/event-bus.service';
import { DrugCollectionService } from '../../Services/drug-collection.service';
import { LocalStorageService } from '../../Services/local-storage.service';
import { SignalRService } from '../../Services/signalr.service';
import { FaqService } from '../../Services/faq.service';
import { Router } from '@angular/router';
import { TrackerService } from '../../Services/tracker.service';
import { TranslocoService, LangDefinition } from '@jsverse/transloco';
import { Subscription, take } from 'rxjs';
import { ConfigService } from '../../Services/config/config.service';

declare let jQuery: any;
@Component({
  selector: 'amzn-drug-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  translocoService = inject(TranslocoService);
  availableLangs = this.translocoService.getAvailableLangs() as LangDefinition[];
  private translocoSub1: Subscription;
  private translocoSub2: Subscription | null;

  helpModalContent: string;
  errorMsg: string;
  disableHelpRequestButton = false;
  showTranslationDropdown = false;
  hideHelpButton = true;
  faq: string;
  currentLanguageSetting;
  translationOptions = [
    { value: 'en-US', label: 'English' },
    { value: 'es-419', label: 'Español' },
  ];

  constructor(
    private eventBus: EventBusService,
    private drugCollectionService: DrugCollectionService,
    private tracker: TrackerService,
    private localStorageService: LocalStorageService,
    private signalRService: SignalRService,
    private faqService: FaqService,
    private router: Router,
    configService: ConfigService,
  ) {
    configService.settings().subscribe((settings) => {
      this.showTranslationDropdown = settings.find((kv) => kv.key === 'showTranslationDropdown')?.value === 'true';
    });
  }

  get activeLang() {
    return this.translocoService.getActiveLang();
  }

  ngOnInit(): void {
    const tempLang = this.translationOptions.filter((lang) => lang.value === this.activeLang);
    this.currentLanguageSetting = tempLang[0];
    this.errorMsg = '';
    this.faqService.faqHtml.subscribe((updatedFaq) => {
      this.faq = updatedFaq;
    });

    this.eventBus.helpContentAnnounced$.subscribe((item) => {
      const inviteId = this.localStorageService.getItem('inviteId');
      this.disableHelpRequestButton = !inviteId;

      if (item) {
        this.helpModalContent = item.help_content ? item.help_content : '';
        this.hideHelpButton = !!item.hideHelpButton && item.hideHelpButton ? true : false;

        if (!this.disableHelpRequestButton) {
          this.disableHelpRequestButton =
            !!item.disableHelpRequestButton && item.disableHelpRequestButton ? true : false;
        }
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.translocoSub1 = this.translocoService.langChanges$.subscribe((_lang) => {
      const tempLang = this.translationOptions.filter((_lang) => _lang.value === this.activeLang);
      this.currentLanguageSetting = tempLang[0];
    });

    const inviteId = this.localStorageService.getItem('inviteId');
    if (inviteId) {
      const localStorageLang = this.localStorageService.getItem('selectedTranslationLanguage');
      if (localStorageLang) {
        this.changeLang(localStorageLang);
      }
    }
  }

  changeLang(lang: string) {
    // Ensure new active lang is loaded
    this.translocoSub2?.unsubscribe();
    this.translocoSub2 = this.translocoService
      .load(lang)
      .pipe(take(1))
      .subscribe(() => {
        this.translocoService.setActiveLang(lang);
        this.localStorageService.setItem('selectedTranslationLanguage', lang);
      });
    const inviteId = this.localStorageService.getItem('inviteId');
    if (inviteId) {
      this.drugCollectionService.updateSelectedTranslationLanguage(inviteId, lang).subscribe({
        error: (error) => {
          console.error(error);
        },
      });
    }
  }

  ngOnDestroy() {
    this.translocoSub1.unsubscribe();
    this.translocoSub2?.unsubscribe();
    this.translocoSub2 = null;
  }

  onTranslationLanguageChange(language) {
    this.changeLang(language);
    const tempLang = this.translationOptions.filter((lang) => lang.value === language);
    this.currentLanguageSetting = tempLang[0];
  }

  openHelpModal(): void {
    this.matomoLog('show help instructions');
    jQuery('#help-modal').modal('show');
  }

  closeHelpModal(): void {
    jQuery('#help-modal').modal('hide');
  }

  helpRequest(): void {
    jQuery('#help-modal').modal('hide');
    jQuery('#help-request-modal').modal('show');

    this.matomoLog('request help');

    // POST request to admin
    this.postRequestHelp();

    // Subscribe to SignalR messaging
    this.signalRService.startConnection();
    this.signalRService.hubConnection.on('newMessage', (message) => {
      this.processSignalrMessage(message);
    });
  }

  processSignalrMessage(message: any): void {
    if (message !== undefined) {
      if (message.helpStatus !== undefined && message.helpStatus === 'resolved') {
        if (message.step && message.step === 'info_retake_test') {
          this.retakeTest();
        }
        jQuery('#help-request-modal').modal('hide');
      }
    }
  }

  retakeTest(): void {
    this.tracker.matomoTrackEvent('help', 'result : retaketest', 'redirect to open test kit help page');
    this.localStorageService.setItem('admin_result', 'retake-test');
    this.router.navigate(['admin-result']);
  }

  cleanUp(): void {
    this.tracker.matomoTrackEvent('admin triggered abandoned', 'result : abandoned', 'redirect to info cleanup');
    this.router.navigate(['info_cleanup']);
  }

  cancelHelpRequest(): void {
    const location = this.localStorageService.getItem('location_code');

    const body = {
      location,
      station: 'One',
    };

    this.drugCollectionService.postRequestAdminHelp(body, 'notRequested').subscribe({
      next: () => {
        jQuery('#help-request-modal').modal('hide');
      },
      error: (error) => {
        jQuery('#help-request-modal').modal('hide');
        console.error(error.errorMessage, ['get help : request admin post'], ['server']);
      },
    });
  }

  closeErrorPopup(): void {
    jQuery('#error-modal').modal('hide');
  }

  matomoLog(message: string): void {
    const path = location.hash.substr(2).split('/');
    let componentName = path[0];
    const pageName = path[1] !== undefined && path[1] !== '' ? path[1] + ' help clicked' : 'get help clicked';

    if (componentName === 'review-candidate' || componentName === '') {
      componentName = 'auth';
    } else if (componentName === 'capture-photo') {
      componentName = 'camera capture';
    } else if (componentName === 'admin-result') {
      componentName = 'admin result';
    }

    this.tracker.matomoTrackEvent('help', 'clicked', componentName + ' : ' + pageName + ', ' + message);
  }

  postRequestHelp(): void {
    const location = this.localStorageService.getItem('location_code');

    const body = {
      location,
      station: 'One',
    };

    this.drugCollectionService.postRequestAdminHelp(body, 'requested').subscribe({
      error: (error) => {
        console.error(error.errorMessage, ['get help : request admin post'], ['server']);
      },
    });
  }

  getKitType(): string {
    return `
<h3>What is OralTox?</h3>
<p>OralTox is a rapid oral fluid drug test manufactured by Premier Biotech. The OralTox screening test provides
results in less than seven minutes, identifying the absence or presence of certain drugs.</p>
<h3>How long does an OralTox collection take to complete?</h3>
<p>Most donors will saturate the collection sponge within two to four minutes. However, a collection for individuals
who experience dry mouth may exceed four minutes.  If after seven minutes the saturation indicator has not changed
color, the OralTox device must be discarded and the test repeated. Once the saturation indicator strip appears in the
indicator window of the swab, the collection is complete.</p>
<h3>How is this testing method different than lab testing?</h3>
<p>This testing process allows your employer to oversee testing while donors collect their own oral fluids/saliva
 samples for testing. This method of oral fluid testing reduces the likelihood of device and sample tampering
 without requiring laboratory appointments and testing.</p>
<p>You are also required to take and submit a photo of the testing device. If you are using your own smart phone,
you will be asked to enable access to your device’s camera in order to take the photo.</p>
<p>Please note that if this screening test is inconclusive, a confirmatory laboratory test will be conducted.</p>
 `;
  }
}
