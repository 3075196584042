import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AudioService } from '../../Services/audio.service';
import { DrugCollectionService } from '../../Services/drug-collection.service';
import { FaqService } from '../../Services/faq.service';
import { TrackerService } from '../../Services/tracker.service';

@Component({
  selector: 'amzn-page-template-blank',
  templateUrl: './page-template-blank.component.html',
  styleUrls: ['./page-template-blank.component.scss'],
})
export class PageTemplateBlankComponent implements OnInit, AfterViewInit {
  @Input()
  title: string;

  @Input()
  category: string;

  @Input()
  step: string;

  @Input()
  faqContent?: string = '';

  @Input()
  audioFile?: string;

  @Output()
  statusUpdate: EventEmitter<string> = new EventEmitter<string>();

  tracker: TrackerService;

  constructor(
    tracker: TrackerService,
    private drugCollectionService: DrugCollectionService,
    private faqService: FaqService,
    private audioService: AudioService,
  ) {
    this.tracker = tracker;
  }

  ngOnInit(): void {
    this.tracker.matomoTrackPageView(this.step, window.location.hash.substr(1));
    this.drugCollectionService.postStatusCall(this.step).subscribe({
      next: () => {
        this.statusUpdate.emit(this.step);
      },
    });

    this.faqService.setFaqHtml(this.faqContent);
    if (this.audioFile) {
      this.audioService.emitAudioFileName(this.audioFile);
    }
  }

  ngAfterViewInit(): void {
    this.tracker.matomoTrackEvent(this.category, 'visited', this.title);
  }
}
