import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventBusService {
  helpContent = new Subject<any>();
  helpContentAnnounced$ = this.helpContent.asObservable();

  helpContentAnnounced(mission: any): void {
    this.helpContent.next(mission);
  }
}
