import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
import { SubmitImageRequest } from '../interfaces/submit-image-request.interface';
import { SubmitImageResponse } from '../interfaces/submit-image-response.interface';
import { Station } from '../interfaces/station.interface';
import { LookupOrderRequest } from '../interfaces/lookup-order-request.interface';
import { LookupOrderResponse } from '../interfaces/lookup-order-response.interface';
import { GetLocationResponse } from '../interfaces/get-location-response.interface';
import { LookupInviteRequest } from '../interfaces/lookup-invite-request.interface';
import { LookupInviteResponse } from '../interfaces/lookup-invite-response.interface';
import { StatusResponse } from '../interfaces/status-response.interface';
import { StatusPost } from '../interfaces/status-post.interface';
// eslint-disable-next-line max-len
import { UpdateSelectedTranslationLanguagePost } from '../interfaces/update-selected-translation-language-post.interface';
import { SkuCheckResponse } from '../interfaces/sku-check-response.interface';
import { CheckDeviceRequest } from '../interfaces/check-device-request.interface';
import { UpdateContactInformationRequest } from '../interfaces/update-contact-information-request.interface';
import { Disclosure } from '../interfaces/disclosure.interface';
import { MroAdvanceNotificationSelectionPost } from '../interfaces/mroAdvanceNotificationSelection-post.interface';

@Injectable({
  providedIn: 'root',
})
export class DrugCollectionService {
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
  ) {}

  lookupOrder(body: LookupOrderRequest): Observable<LookupOrderResponse> {
    const url = '/api/LookupOrder';
    return this.http.post<LookupOrderResponse>(url, body);
  }

  getLocation(locationId: string): Observable<GetLocationResponse> {
    return this.http.get<GetLocationResponse>(`/api/getLocation/${locationId}`);
  }

  lookupInvite(body: LookupInviteRequest): Observable<LookupInviteResponse> {
    const url = '/api/lookupInvite';
    return this.http.post<LookupInviteResponse>(url, body);
  }

  getStatus(id: string): Observable<StatusResponse> {
    const url = '/api/status/' + id;
    return this.http.get<StatusResponse>(url).pipe(map((res) => this.extractData(res)));
  }

  postStatusCall(step: string): Observable<StatusResponse> {
    const id = this.localStorageService.getItem('inviteId');
    const url = '/api/status/' + id;

    const body: StatusPost = {
      step,
    };
    return this.http.post<StatusResponse>(url, body).pipe(map((res) => this.extractData(res)));
  }

  checkDevice(body: CheckDeviceRequest): Observable<any> {
    const id = this.localStorageService.getItem('inviteId');
    return this.http.post('/api/CheckDevice/' + id, body);
  }

  submitDeviceImage(body: SubmitImageRequest): Observable<SubmitImageResponse> {
    const id = this.localStorageService.getItem('inviteId');

    const matchExp = /^data.*base64,/;
    if (body.deviceImageString) {
      body.deviceImageString = body.deviceImageString.replace(matchExp, '');
    }

    if (body.applicantElectronicConsentImageString) {
      body.applicantElectronicConsentImageString = body.applicantElectronicConsentImageString.replace(matchExp, '');
    }

    if (body.stateConsentImageString) {
      body.stateConsentImageString = body.stateConsentImageString.replace(matchExp, '');
    }
    if (body.deviceImageString) {
      body.deviceImageString = body.deviceImageString.replace(matchExp, '');
    }

    if (body.sealedDeviceImageFrontString) {
      body.sealedDeviceImageFrontString = body.sealedDeviceImageFrontString.replace(matchExp, '');
    }

    if (body.sealedDeviceImageBackString) {
      body.sealedDeviceImageBackString = body.sealedDeviceImageBackString.replace(matchExp, '');
    }

    return this.http.post<SubmitImageResponse>('/api/SubmitImage/' + id, body);
  }

  extractData(res: StatusResponse): any {
    return res;
  }

  postRequestAdminHelp(body: any, requestType: string): Observable<ArrayBuffer> {
    let url = '';
    const inviteId = this.localStorageService.getItem('inviteId');
    url = '/api/Help/' + inviteId + '/' + requestType;

    return this.http.post<ArrayBuffer>(url, body);
  }

  updateContactInformation(body: UpdateContactInformationRequest): Observable<any> {
    const inviteId = this.localStorageService.getItem('inviteId');
    const url = '/api/updateContactInformation/' + inviteId;
    return this.http.post(url, body);
  }

  setStation(station: Station): Observable<Station> {
    return this.http.post<Station>('/api/stations', station);
  }

  getDisclosure(name: string, language: string): Observable<Disclosure> {
    return this.http.get<Disclosure>(`/api/disclosures/${name}?language=${language}`);
  }

  processDisclosure(disclosure: Disclosure, action: string): Observable<any> {
    const body = {
      name: disclosure.name,
      version: disclosure.version,
    };
    const inviteId = this.localStorageService.getItem('inviteId');
    return this.http.post<any>(`/api/processDisclosure/${inviteId}/${action}`, body);
  }

  sendEmailResult(shouldEmailResultChecked: boolean): Observable<any> {
    const inviteId = this.localStorageService.getItem('inviteId');
    const body = { shouldEmailResult: shouldEmailResultChecked };
    return this.http.post(`/api/updateCandidateEmailFlag/${inviteId}`, body);
  }

  checkDeviceReferenceCode(sku: string): Observable<SkuCheckResponse> {
    const id = this.localStorageService.getItem('inviteId');
    return this.http.get<SkuCheckResponse>(`/api/checkDeviceSku/${id}/${sku}`);
  }

  updateMroAdvanceNotificationSelection(mroAdvanceNotificationSelection: string): Observable<any> {
    const inviteId = this.localStorageService.getItem('inviteId');

    const body: MroAdvanceNotificationSelectionPost = {
      mroAdvanceNotificationSelection,
    };

    return this.http.post(`/api/updateMroAdvanceNotificationSelection/${inviteId}`, body);
  }

  updateSelectedTranslationLanguage(inviteId: string, selectedTranslationLanguage: string): Observable<any> {
    const body: UpdateSelectedTranslationLanguagePost = {
      selectedTranslationLanguage,
    };
    return this.http.post(`/api/UpdateSelectedTranslationLanguage/${inviteId}`, body);
  }
}
