import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../../Services/config/config.service';

@Component({
  selector: 'amzn-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  releaseVersion!: string;
  releaseDate!: string;
  buildVersion!: string;
  buildDate!: string;

  constructor(private config: ConfigService) {}

  ngOnInit(): void {
    this.config.config().subscribe((conf) => {
      this.releaseVersion = conf.releaseNumber;
      this.releaseDate = conf.releaseDate;
      this.buildDate = conf.buildDate;
      this.buildVersion = conf.buildNumber;
    });
  }
}
