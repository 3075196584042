import { Component } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../shared/Services/local-storage.service';
import { DrugCollectionService } from '../../shared/Services/drug-collection.service';
import { Station } from '../../shared/interfaces/station.interface';

@Component({
  selector: 'amzn-station',
  templateUrl: './station.component.html',
  styleUrls: ['./station.component.scss'],
})
export class StationComponent {
  readonly form = new UntypedFormGroup({
    station: new UntypedFormControl(0, Validators.compose([Validators.min(1), Validators.max(99)])),
  });

  isValidStation = true;

  constructor(
    private router: Router,
    private drugCollectionService: DrugCollectionService,
    private localStorageService: LocalStorageService,
  ) {}

  get station(): AbstractControl {
    return this.form.get('station');
  }

  next(): void {
    if (this.form.valid) {
      this.isValidStation = true;
      const id = this.localStorageService.getItem('inviteId');
      const body: Station = {
        inviteId: id,
        stationNum: this.station.value,
      };
      this.drugCollectionService.setStation(body).subscribe({
        next: (station) => {
          this.localStorageService.setItem('booth_number', station.stationNum);
          this.router.navigateByUrl('/review-candidate');
        },
        error: (err) => {
          console.error(err);
        },
      });
    } else {
      this.isValidStation = false;
    }
  }
}
