<amzn-page-template-blank [title]="title" [step]="step" [category]="category" [faqContent]="faqContent" [audioFile]="audioFile">
  <ng-content></ng-content>
  <div class="row btnAlign">
    <div class="col-12 m-b-20 mx-auto">
      <button type="button" [disabled]="primaryDisabled" class="btn button btnActive primaryButton" (click)="clickPrimaryButton()">{{ primaryText }}</button>
    </div>
    <div class="col-12 m-b-40 mx-auto">
      <button type="button" class="btn button backButton" (click)="back()">{{ secondaryText }}</button>
    </div>
  </div>
</amzn-page-template-blank>
