<div class="container-fluid" *ngIf="audioFileName">
  <div *transloco="let t; prefix: 'audioComponent'">
    <div class="row d-flex anime-clean contentArea audioContainer">
      <div>{{ t('audioText') }}:</div>
      <button id="toggleMuteBtn" type="button" attr.aria-label="{{ audioAriaLabel }}" class="audio-btn" (click)="toggleMute()">
        <img [src]="audioImg" alt="" />
      </button>
      <button type="button" attr.aria-label="{{ t('audioReplayButtonAriaLabel') }}" class="audio-btn" (click)="replay()">
        <img src="assets/images/replay.svg" alt="" />
      </button>
      <a
        href="{{ audioTrancriptFileName }}"
        download
        id="downloadAudioTranscript"
        type="button"
        attr.aria-label="{{ t('downloadAudioTranscriptButtonAriaLabel') }}"
        class="audio-btn"
      >
        <img src="assets/images/downloadTranscript.svg" width="24px" alt="" />
      </a>
    </div>
  </div>
</div>

<audio id="amznAudio" #amznAudio [src]="audioFileName"></audio>
