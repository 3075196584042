import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HelpModalComponent } from '../components/help-modal/help-modal.component';
import { DisplayError } from '../interfaces/display-error.interface';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements HttpInterceptor {
  isModalClosed = true;
  helpModalRef: NgbModalRef;
  constructor(
    private modalService: NgbModal,
    private translocoService: TranslocoService,
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const displayError = this.convertToDisplayError(error);
        if (this.isStatusUpdateError(error) || this.isAlreadyAdjudicatedError(error)) {
          displayError.message = this.translocoService.translate('errorHandlerService.displayError');
          this.openModal(displayError);
        }
        return throwError(error);
      }),
    );
  }

  isStatusUpdateError(error: HttpErrorResponse): boolean {
    return error.status === 422 && error?.url?.includes('status');
  }

  isAlreadyAdjudicatedError(error: HttpErrorResponse): boolean {
    return (
      error.status === 404 &&
      error?.url?.includes('status/null') &&
      !this.getWindowLocationHref().includes('admin-result')
    );
  }

  openModal(displayError: DisplayError): void {
    if (this.isModalClosed) {
      this.isModalClosed = false;
      this.helpModalRef = this.modalService.open(HelpModalComponent, {
        windowClass: 'defaultScrollbars',
        ariaLabelledBy: 'helpRequestedModal',
      });
      this.helpModalRef.result.then(() => (this.isModalClosed = true)).catch(() => (this.isModalClosed = true));
      this.helpModalRef.componentInstance.error = displayError;
    }
  }

  convertToDisplayError(error: HttpErrorResponse): DisplayError {
    const displayError = { errorCode: error.status, message: error.message, errorMessage: error.error };
    if (error.error && error.error.message && error.error.errorCode) {
      displayError.errorCode = error.error.errorCode;
      displayError.message = error.error.message;
    }
    return displayError;
  }

  getWindowLocationHref(): string {
    return window.location.href;
  }
}
