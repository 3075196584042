<div *transloco="let t; prefix: 'dynamicAgreementComponent'" class="container-fluid">
  <amzn-title>{{ disclosure.title }}</amzn-title>

  <div class="anime-clean agreement-content-area">
    <div class="row m-0 mt-4">
      <div class="mb-3 back-container">
        <button type="button" class="mx-3 back-disclosure-text btn-no-border" (click)="back()">
          <img src="../../../assets/images/back-vector.png" alt="" /> {{ t('backButton') }}
        </button>
      </div>
      <div class="col-12 p-3 consentCard">
        <div class="consentAgreementInstructions" [innerHTML]="disclosure.content"></div>
      </div>
    </div>

    <div class="row btnAlign mt-5">
      <div class="col-12 ml-5 email-checkbox" *ngIf="showEmailCheckbox">
        <label for="email-checkbox"> {{ t('freeCopyOfDrugResultsInstruction') }} </label>
        <input type="checkbox" id="email-checkbox" [checked]="shouldEmailResult" (click)="setEmailResult($event)" />
      </div>
      <div class="col-6 mx-auto m-b-40">
        <button type="button" class="btn button" (click)="decline()">{{ t('declineButton') }}</button>
        <div class="mt-3 agreement-decline-text">{{ t('declineWarning') }}</div>
      </div>

      <div class="col-6 mx-auto m-b-40">
        <button type="button" class="btn button btnActive" (click)="agree()">{{ t('agreeButton') }}</button>
      </div>
    </div>
  </div>
</div>
