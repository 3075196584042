import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountdownBarComponent } from './components/countdown-bar/countdown-bar.component';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { TimeformatPipe } from './pipes/timeformat.pipe';
import { HelpModalComponent } from './components/help-modal/help-modal.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { AudioComponent } from './components/audio/audio.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { TitleBarComponent } from './components/title-bar/title-bar.component';
import { DynamicAgreementComponent } from './components/dynamic-agreement/dynamic-agreement.component';
// eslint-disable-next-line max-len
import { DisclosureDeclineModalComponent } from './components/disclosure-decline-modal/disclosure-decline-modal.component';
import { DynamicSignatureComponent } from './components/dynamic-signature/dynamic-signature.component';
import { PageTemplateComponent } from './components/page-template/page-template.component';
import { PageTemplateBlankComponent } from './components/page-template-blank/page-template-blank.component';
import { ErrorToMessagePipe } from './pipes/error-to-message.pipe';
import { FadvInputComponent, FadvSignatureComponent } from '@fa-switch-sharedservices/ng-elements';
import { TranslocoModule } from '@jsverse/transloco';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
@NgModule({
  declarations: [
    CountdownBarComponent,
    TimeformatPipe,
    HelpModalComponent,
    ErrorModalComponent,
    AudioComponent,
    FooterComponent,
    HeaderComponent,
    TitleBarComponent,
    DynamicAgreementComponent,
    DisclosureDeclineModalComponent,
    DynamicSignatureComponent,
    PageTemplateComponent,
    PageTemplateBlankComponent,
    ErrorToMessagePipe,
  ],
  imports: [
    CommonModule,
    NgbProgressbarModule,
    NgxMaskModule.forRoot(),
    FadvSignatureComponent,
    FadvInputComponent,
    TranslocoModule,
  ],
  exports: [
    NgxMaskModule,
    CountdownBarComponent,
    HelpModalComponent,
    ErrorModalComponent,
    AudioComponent,
    FooterComponent,
    HeaderComponent,
    TitleBarComponent,
    DynamicAgreementComponent,
    DisclosureDeclineModalComponent,
    DynamicSignatureComponent,
    PageTemplateComponent,
    PageTemplateBlankComponent,
    ErrorToMessagePipe,
  ],
  providers: [ErrorToMessagePipe],
})
export class SharedModule {}
