<div id="countDownTimerBar" tabindex="0" aria-labelledby="countdown-timer">
  <ngb-progressbar
    *transloco="let t; prefix: 'countdownBarComponent'"
    [value]="value"
    height="40px"
    aria-label="{{ t('progressTimerAriaLabel') }}"
    id="progress-timer"
    role="timer"
    aria-hidden="true"
  >
    <span class="progress-text">{{ timeLeft | timeformat }}</span>
    <div id="countdown-timer" hidden>{{ t('deviceCountdownTimerAriaLabel') }}</div>
  </ngb-progressbar>
</div>
<div id="hiddenTimerStatusUpdates" aria-live="polite" role="status" class="visually-hidden"></div>
<div id="hiddenCurrentTimeRemainingStatus" aria-live="polite" role="status" class="visually-hidden"></div>
