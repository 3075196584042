import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';

export const REFRESH_HOME_KEY = 'shouldRefreshWelcome';
@Injectable({
  providedIn: 'root',
})
export class RefreshService {
  constructor(private localStorage: LocalStorageService) {}

  refreshOnce(pageKey: string): void {
    const shouldRefresh = this.localStorage.getItem(pageKey);
    if (shouldRefresh === 'true') {
      this.localStorage.setItem(pageKey, 'false');
      window.location.reload();
    } else {
      this.localStorage.setItem(pageKey, 'true');
    }
  }

  refreshWelcomePage(): void {
    this.refreshOnce(REFRESH_HOME_KEY);
  }
}
