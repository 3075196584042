import { Injectable } from '@angular/core';
import { MatomoTracker } from 'ngx-matomo';
import { environment } from 'projects/collect-web/src/environments/environment';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class TrackerService {
  constructor(
    private localStorageService: LocalStorageService,
    private matomoTracker: MatomoTracker,
  ) {}

  log(body: any): void {
    const now = new Date();
    let inviteId = this.localStorageService.getItem('inviteId');
    if (!inviteId || inviteId === '') {
      inviteId = 'N/A';
    }
    body.id = now.getTime();

    if (body.log_type === 'error') {
      this.matomoTrackEvent(body.log_type, body.error_origin, body.message + ' - ' + body.stack);
    }
  }

  matomoTrackEvent(category: string, action: string, name: string): void {
    if (!this.isProductionEnvironment()) {
      console.log('[TrackingService] Track Event: ' + category + ' : ' + action + ' : ' + name);
    }
    this.matomoTracker.trackEvent(category, action, name);
  }

  matomoTrackPageView(title: string, url: string): void {
    if (!this.isProductionEnvironment()) {
      console.log('[TrackingService] Track Page View: ' + title + ' : ' + url);
    }
    this.matomoTracker.setCustomUrl(url);
    this.matomoTracker.trackPageView(title);
    this.matomoTracker.enableHeartBeatTimer(1);
  }

  matomoSetTitle(title: string): void {
    if (!this.isProductionEnvironment()) {
      console.log('[TrackingService] Set Title: ' + title);
    }
    this.matomoTracker.setDocumentTitle(title);
  }

  isProductionEnvironment(): boolean {
    return environment.production;
  }
}
