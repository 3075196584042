<amzn-page-template-blank *transloco="let t; prefix: 'stationComponent'" title="{{ t('stationBanner') }}" step="station" audioFile="enter-booth">
  <form [formGroup]="form" novalidate autocomplete="off" (ngSubmit)="next()">
    <div class="anime-clean contentArea">
      <div class="row m-0 mt-4">
        <div class="form-group col-12">
          <label for="station">{{ t('boothForm') }}<span class="req" title="required">*</span></label>
          <input
            type="number"
            id="station"
            aria-required="true"
            class="form-control col-3"
            formControlName="station"
            [ngClass]="{ 'is-invalid': station.touched && station.errors }"
          />
        </div>
      </div>
      <div *ngIf="!isValidStation" class="row m-0 mt-4">
        <div class="form-group col-12 text-center">
          <label class="text-danger" role="alert">{{ t('boothInvalidError') }}</label>
        </div>
      </div>
      <div class="row btnAlign">
        <div class="col-12 m-b-40 mx-auto mt-5">
          <button type="submit" data-testid="getStarted" class="btn button btnActive">{{ t('nextButton') }}</button>
        </div>
      </div>
    </div>
  </form>
</amzn-page-template-blank>
