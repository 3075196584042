/* eslint-disable @typescript-eslint/member-ordering */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'amzn-countdown-bar',
  templateUrl: './countdown-bar.component.html',
  styleUrls: ['./countdown-bar.component.scss'],
})
export class CountdownBarComponent {
  @Output()
  done = new EventEmitter();

  @Input()
  set countdownValue(timer: number) {
    this.countdown = timer;
    this.start();
  }

  get countdownValue(): number {
    return this.countdown;
  }

  timeLeft = 100;
  value = 0;
  hiddenAlertContent: string;
  hiddenStatusContent: string;

  private countdown = 100;
  private interval;

  constructor(private translocoService: TranslocoService) {}

  start(): void {
    this.timeLeft = this.countdown;
    document.getElementById('hiddenTimerStatusUpdates').textContent = '';
    document.getElementById('hiddenTimerStatusUpdates').textContent = `${this.reportTimeProperly(
      this.countdown,
      true,
    )}`;
    const countDownTimerBar = document.getElementById('countDownTimerBar');
    countDownTimerBar.addEventListener('focus', this.reportCurrentTimerStatus.bind(this));
    if (this.timeLeft > 0) {
      this.interval = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
          this.value = (this.timeLeft * 100) / this.countdown;
        } else {
          this.value = 0;
          clearInterval(this.interval);
          document.getElementById('hiddenTimerStatusUpdates').textContent = this.translocoService.translate(
            'countdownBarComponent.countdownTimerFinished',
          );
          this.done.emit();
        }
      }, 1000);
    } else {
      this.value = 0;
      document.getElementById('hiddenTimerStatusUpdates').textContent = this.translocoService.translate(
        'countdownBarComponent.countdownTimerFinished',
      );
    }
  }

  reportTimeProperly(value: number, start: boolean): string {
    let result;
    const minutes = Math.floor(value / 60);
    const seconds = value % 60;
    if (minutes > 0 && seconds >= 1 && start) {
      result = this.translocoService.translate('countdownBarComponent.countdownStarted', {
        timerMinutes: minutes,
        timerSeconds: seconds,
      });
    } else if (minutes > 0 && start) {
      result = this.translocoService.translate('countdownBarComponent.countdownStartedMinutes', {
        timerMinutes: minutes,
      });
    } else if (seconds >= 1 && start) {
      result = this.translocoService.translate('countdownBarComponent.countdownStartedSeconds', {
        timerSeconds: seconds,
      });
    } else if (minutes > 0 && seconds >= 1 && !start) {
      result = this.translocoService.translate('countdownBarComponent.countdownHasRemaining', {
        timerMinutes: minutes,
        timerSeconds: seconds,
      });
    } else if (minutes > 0 && !start) {
      result = this.translocoService.translate('countdownBarComponent.countdownHasRemainingMinutes', {
        timerMinutes: minutes,
      });
    } else if (seconds >= 1 && !start) {
      result = this.translocoService.translate('countdownBarComponent.countdownHasRemainingSeconds', {
        timerSeconds: seconds,
      });
    } else {
      result = this.translocoService.translate('countdownBarComponent.countdownTimerFinished');
    }
    return result;
  }

  reportCurrentTimerStatus() {
    document.getElementById('hiddenCurrentTimeRemainingStatus').textContent = '';
    document.getElementById('hiddenCurrentTimeRemainingStatus').textContent = `${this.reportTimeProperly(
      this.timeLeft,
      false,
    )}`;
  }
}
