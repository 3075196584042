<div *transloco="let t; prefix: 'reviewCandidateComponent'" class="container-fluid">
  <amzn-title>{{ t('reviewCandidateBanner') }}</amzn-title>
  <div class="anime-clean contentArea">
    <div class="row m-0 mt-4">
      <div class="col-12">
        <div class="addressDetails text-center">
          <h2>{{ firstName }} {{ lastName }}</h2>
          <p class="mb-0">{{ addressLine1 }}</p>
          <p>{{ city }}, {{ state }} {{ zip }}</p>
        </div>
      </div>
    </div>
    <div class="row m-0">
      <div class="col-12">
        <div class="emailArea text-center" *ngIf="!editingContacts">
          <p>{{ emailAddress.value }}</p>
        </div>
        <div class="phoneNumberArea text-center" *ngIf="!editingContacts">
          <p>{{ phoneNumber.value }}</p>
        </div>
        <div class="text-center mb-4">
          <form [formGroup]="candidateForm" *ngIf="editingContacts">
            <div>
              <label for="emailAddress">{{ t('updateEmailForm') }}<span class="req" title="required">*</span></label
              ><input
                class="mb-3"
                id="emailAddress"
                aria-required="true"
                placeholder="{{ t('updateEmailPlaceholder') }}"
                type="email"
                formControlName="emailAddress"
              />
              <div class="error" *ngIf="!emailValid()" role="alert">{{ t('updateEmailInvalidError') }}</div>
              <label for="phoneNumber">{{ t('updatePhoneNumberForm') }}<span class="req" title="required">*</span></label
              ><input
                id="phoneNumber"
                aria-required="true"
                placeholder="(000) 000-0000"
                mask="(000) 000-0000"
                type="tel"
                formControlName="phoneNumber"
                (keyup)="validatePhoneNo()"
              />
              <div class="error" *ngIf="!phoneValid()" role="alert">{{ t('updatePhoneNumberInvalidError') }}</div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="row m-0 mt-0">
      <div class="col-12">
        <div class="phoneNumDesc text-center">
          <p>{{ t('reviewCandidateInstruction') }}</p>
        </div>
      </div>
    </div>

    <div class="row btnAlign">
      <div class="col-12 m-b-40 mx-auto">
        <button type="button" class="btn button btnActive" (click)="goToNextStage()">{{ t('nextButton') }}</button>
      </div>
      <div class="col-12 m-b-40 mx-auto" *ngIf="!editingContacts">
        <button type="button" class="btn button" (click)="editContactInfo()">{{ t('updatePhoneOrEmailButton') }}</button>
      </div>
      <div class="col-12 m-b-40 mx-auto" *ngIf="editingContacts">
        <button type="button" class="btn button" (click)="cancel()">{{ t('cancelButton') }}</button>
      </div>
    </div>
  </div>
</div>
