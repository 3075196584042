import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatomoTracker } from 'ngx-matomo';
import { delay } from 'rxjs/operators';
import { AudioService } from './shared/Services/audio.service';
import { ConfigService } from './shared/Services/config/config.service';
import { LocalStorageService } from './shared/Services/local-storage.service';
import { SignalRService } from './shared/Services/signalr.service';
import { DemoModeService } from './shared/Services/demo-mode/demo-mode.service';
import { TrackerService } from './shared/Services/tracker.service';
import { FadvStylesService } from '@fa-switch-sharedservices/ng-elements';
import { Subscription } from 'rxjs';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'amzn-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  canPlay: boolean;
  audioFileName: string;
  audioTrancriptFileName: string;
  title = 'collect-web';
  translocoSub1: Subscription;

  constructor(
    private matomoTracker: MatomoTracker,
    private router: Router,
    private audioService: AudioService,
    private localStorageService: LocalStorageService,
    private signalRService: SignalRService,
    private tracker: TrackerService,
    private configService: ConfigService,
    private demoModeService: DemoModeService,
    private fadvStylesService: FadvStylesService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.configService.config().subscribe(() => {
      this.matomoTracker.trackPageView();
    });
    if (this.demoModeService.demoMode) {
      this.demoModeService.setDemeThemeCSS();
    }
    this.fadvStylesService.setScheme('light');

    this.audioService.playAudio.pipe(delay(0)).subscribe((play) => (this.canPlay = play));
    this.audioService.audioFileName.pipe(delay(0)).subscribe((fileName) => (this.audioFileName = fileName));
    this.audioService.audioTrancriptFileName
      .pipe(delay(0))
      .subscribe((fileName) => (this.audioTrancriptFileName = fileName));

    if (this.localStorageService.getItem('playAudio')) {
      this.audioService.emitPlayAudio(this.localStorageService.getItem('playAudio'));
    }
    this.signalRService.$abandoned.subscribe((message) => this.cleanUp(message));

    this.translocoSub1 = this.translocoService.langChanges$.subscribe(() => {
      this.audioService.switchAudioTranslations();
    });
  }

  ngOnDestroy(): void {
    this.translocoSub1.unsubscribe();
  }

  cleanUp(message: string): void {
    if (message === 'abandoned') {
      this.tracker.matomoTrackEvent('admin triggered abandoned', 'result : abandoned', 'redirect to info cleanup');
      this.router.navigate(['admin-result', 'cleanup-help']);
    }
  }
}
