<div *transloco="let t; prefix: 'dynamicSignatureComponent'" class="container-fluid">
  <amzn-title>{{ disclosure.title }}</amzn-title>
  <div class="anime-clean agreement-content-area">
    <div class="row m-0 mt-4">
      <div class="col-12 p-3 consentCard">
        <div class="consentSignatureInstructions" [innerHTML]="disclosure.content"></div>
      </div>
    </div>
    <div class="row m-0 mt-3 mb-4 signature-div">
      <div class="col-9 signature-container">
        <div class="mt-2 signature-instructions">{{ t('signOrDrawSignatureInstruction') }}</div>
        <fadv-input
          id="signatureTypedInput"
          #signatureTypedInput
          label="{{ t('fullNamePlaceholder') }}"
          maxLength="30"
          (inputChanged)="signatureChange()"
        ></fadv-input>
        <fadv-signature
          id="signaturePad"
          #fadvSignaturePad
          [prefilledSignature]="signatureTypedInput.value"
          [drawSignature]="true"
          (changed)="signatureChange()"
        ></fadv-signature>
      </div>
    </div>

    <div class="row btnAlign mt-5">
      <div class="col-6 mx-auto m-b-40">
        <button type="button" class="btn button" (click)="back()">{{ t('backButton') }}</button>
      </div>

      <div class="col-6 mx-auto m-b-40">
        <button type="button" class="btn button btnActive" (click)="submitSignature()" [disabled]="disableSignButton">{{ t('nextButton') }}</button>
      </div>
    </div>
  </div>
</div>
