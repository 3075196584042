import { EventEmitter, Component, Input, OnInit, Output, ViewChild, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Disclosure } from '../../interfaces/disclosure.interface';
import { DrugCollectionService } from '../../Services/drug-collection.service';
import { TrackerService } from '../../Services/tracker.service';
import { SignatureSubmitEvent } from './signature-submit-event.interface';
import { AudioService } from '../../Services/audio.service';
import { FadvInputComponent, FadvSignatureComponent } from '@fa-switch-sharedservices/ng-elements';
import { TranslocoService } from '@jsverse/transloco';
import { filter, pluck, Subscription } from 'rxjs';

declare let jQuery: any;

@Component({
  selector: 'amzn-dynamic-signature',
  templateUrl: './dynamic-signature.component.html',
  styleUrls: ['./dynamic-signature.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DynamicSignatureComponent implements OnInit, OnDestroy {
  @ViewChild('signatureTypedInput')
  signatureTypedInput: FadvInputComponent;

  @ViewChild('fadvSignaturePad')
  fadvSignaturePad: FadvSignatureComponent;

  @Input()
  disclosureName: string;

  @Input()
  backRoute: string;

  @Input()
  step: string;

  @Input()
  audioFile?: string;

  @Output()
  signatureSubmitEvent: EventEmitter<SignatureSubmitEvent> = new EventEmitter();

  disableSignButton = true;

  disclosure: Disclosure = {
    title: '...',
    content: '...',
    id: '',
    name: '',
    version: 0,
    language: '',
  };
  translocoSub1: Subscription;

  constructor(
    private drugCollectionService: DrugCollectionService,
    private tracker: TrackerService,
    private router: Router,
    private audioService: AudioService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.tracker.matomoTrackPageView(this.disclosureName, window.location.hash.substr(1));
    const activeLangauge = this.translocoService.getActiveLang();
    this.drugCollectionService.getDisclosure(this.disclosureName, activeLangauge).subscribe((disclosure) => {
      this.disclosure = disclosure;
    });
    this.drugCollectionService.postStatusCall(this.step).subscribe(() => {
      // intentionally blank
    });
    if (this.audioFile) {
      this.audioService.emitAudioFileName(this.audioFile);
    }
    this.translocoSub1 = this.translocoService.events$
      .pipe(
        filter((e) => e.type === 'langChanged'),
        pluck('payload'),
      )
      .subscribe(({ langName }) => {
        this.drugCollectionService.getDisclosure(this.disclosureName, langName).subscribe((disclosure) => {
          this.disclosure = disclosure;
        });
      });
  }

  back(): void {
    this.tracker.matomoTrackEvent(this.disclosureName, 'clicked', 'back');
    this.router.navigateByUrl(this.backRoute);
  }

  signatureChange(): void {
    if (!this.fadvSignaturePad.drawSignature && this.signatureTypedInput.value) {
      this.disableSignButton = false;
    } else if (this.fadvSignaturePad.drawSignature && this.fadvSignaturePad.save()) {
      this.disableSignButton = false;
    } else {
      this.disableSignButton = true;
    }
  }

  submitSignature(): void {
    this.tracker.matomoTrackEvent(this.disclosureName, 'clicked', 'agreed');
    this.drugCollectionService.processDisclosure(this.disclosure, 'agree').subscribe({
      next: () => {
        const event: SignatureSubmitEvent = {
          signature: this.fadvSignaturePad.save(),
        };
        this.signatureSubmitEvent.emit(event);
      },
      error: (error) => {
        console.error(error.errorMessage, ['confirmation : submit signature image'], ['server']);
        jQuery('#error-modal').modal('show');
        jQuery('#errorContent').html(error.errorMessage);
      },
    });
  }

  ngOnDestroy(): void {
    this.translocoSub1.unsubscribe();
  }
}
