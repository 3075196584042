<div *transloco="let t; prefix: 'disclosureDeclineModalComponent'">
  <div class="mt-3 discloser-modal-alert">
    <img class="alert-exclamation" alt="" />
  </div>
  <div class="discloser-modal-alert-text-1">
    <h1>{{ t('disclosureDeclineHeader') }}</h1>
  </div>
  <hr class="solid mx-3" />
  <div class="mx-3 mt-3 discloser-modal-alert-text-2">
    {{ t('decliningDisclosuresWarning') }}
  </div>
  <hr class="solid mx-3" />
  <div class="mt-3 discloser-modal-alert-button-group">
    <button ngbAutofocus type="button" id="backDisclosureButton" name="backButton" (click)="back()" class="btn discloser-modal-alert-button-1 mb-3">
      <strong>{{ t('noBackButton') }}</strong>
    </button>
    <button type="button" id="declineDisclosureButton" name="declineButton" (click)="decline()" class="btn discloser-modal-alert-button-2 mb-3">
      <strong>{{ t('yesDeclineButton') }}</strong>
    </button>
  </div>
  <div id="disclosureDeclineModal" hidden>{{ t('disclosureDeclineModalAriaLabel') }}</div>
</div>
