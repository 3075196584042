import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';
import { Config } from './config';
import { ConfigKeyValue } from './config-key-value';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private appSettings$: Observable<ConfigKeyValue[]>;
  private appConfig$: Observable<Config>;

  constructor(private http: HttpClient) {
    this.appSettings$ = this.http.get<ConfigKeyValue[]>('/api/settings/applicantUI').pipe(
      map((result: any) => result.settings as ConfigKeyValue[]),
      take(1),
      shareReplay(1),
    );
    this.appConfig$ = this.http.get<Config>('/assets/app.config.json').pipe(take(1), shareReplay(1));
  }

  config(): Observable<Config> {
    return this.appConfig$;
  }

  settings(): Observable<ConfigKeyValue[]> {
    return this.appSettings$;
  }
}
