import { Injectable } from '@angular/core';
import { REFRESH_HOME_KEY } from './refresh.service';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  localStorage: Storage;

  constructor() {
    this.localStorage = window.localStorage;
  }

  setItem(key: any, data: any): void {
    this.localStorage.setItem(key, JSON.stringify(data));
  }

  getItem(key: any): any {
    return JSON.parse(this.localStorage.getItem(key));
  }

  removeItem(key: any): void {
    this.localStorage.removeItem(key);
  }

  clear(): void {
    const shouldRefresh = localStorage.getItem(REFRESH_HOME_KEY);
    const locationUrl = localStorage.getItem('locationUrl');
    this.localStorage.clear();
    localStorage.setItem('locationUrl', locationUrl);
    localStorage.setItem(REFRESH_HOME_KEY, shouldRefresh);
  }
}
