import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

@Pipe({
  name: 'errorCodeToMessage',
})
export class ErrorToMessagePipe implements PipeTransform {
  constructor(private translocoService: TranslocoService) {}

  transform(error: any, isPostHire: boolean): string {
    switch (error?.errorCode?.toUpperCase()) {
      case 'INVALIDDATA':
        if (error?.message) {
          const index = error.message.indexOf('-');
          if (index > -1 && index + 2 < error.message.length) {
            return error.message.substring(index + 2);
          } else {
            return error.message;
          }
        }
        return error?.message ? error.message : this.translocoService.translate('errorToMessagePipe.invalidData');
        +error?.errors;
      case 'INVALIDDATAINVALIDDEVICEQRCODE':
        return this.translocoService.translate('errorToMessagePipe.invalidDataInvalidDeviceQRCode');
      case 'INVALIDDATAINVALIDDEVICEBARCODE':
        return this.translocoService.translate('errorToMessagePipe.invalidDataInvalidDeviceBarCode');
      case 'INVALIDDATAINVALIDDEVICESKU':
        return this.translocoService.translate('errorToMessagePipe.invalidDataInvalidDeviceSku');
      case 'INVALIDDATANOLOCATION':
        return this.translocoService.translate('errorToMessagePipe.invalidDataNoLocation');
      case 'INVALIDDATAINVALIDLOCATION':
        if (error?.message) {
          const index = error.message.indexOf('-');
          if (index > -1 && index + 2 < error.message.length) {
            const id = error.message.substring(0, error.message.indexOf(' is not valid location ID.'));
            return this.translocoService.translate('errorToMessagePipe.invalidDataInvalidLocation', { id: id });
          }
        }
        return this.translocoService.translate('errorToMessagePipe.invalidDataInvalidLocation', { id: '' });
      case 'INVALIDDATAINVALIDLOOKUPORDER':
        if (error?.message) {
          const orderIdStart = error.message.indexOf('LookupOrder for ') + 'LookupOrder for '.length;
          const orderIdEnd = error.message.indexOf(' - location');
          const drugCollectionLocationStart = error.message.indexOf('- location ') + '- location '.length;
          const drugCollectionLocationEnd = error.message.indexOf(' does not exist in the database.');
          const orderId = error.message.substring(orderIdStart, orderIdEnd);
          const drugCollectionLocation = error.message.substring(
            drugCollectionLocationStart,
            drugCollectionLocationEnd,
          );
          return this.translocoService.translate('errorToMessagePipe.invalidDataInvalidLookupOrder', {
            orderId: orderId,
            drugCollectionLocation: drugCollectionLocation,
          });
        }
        return this.translocoService.translate('errorToMessagePipe.invalidDataInvalidLookupOrder', {
          orderId: '',
          drugCollectionLocation: '',
        });
      case 'INVALIDDATAINVALIDDISCLOSUREACTION':
        if (error?.message) {
          const action = error.message.split('ProcessDisclosure - Invalid Disclosure Action ')[1];
          return this.translocoService.translate('errorToMessagePipe.invalidDataInvalidDisclosureAction', {
            action: action,
          });
        }
        return this.translocoService.translate('errorToMessagePipe.invalidDataInvalidDisclosureAction', {
          action: '',
        });
      case 'INVALIDDATAINVALIDDISCLOSURE':
        return this.translocoService.translate('errorToMessagePipe.invalidDataInvalidDisclosure');
      case 'INVALIDDATANODISCLOSURE':
        return this.translocoService.translate('errorToMessagePipe.invalidDataNoDisclosure');
      case 'COLLECTIONCOMPLETED':
        return this.translocoService.translate('errorToMessagePipe.collectionCompleted');
      case 'ADMINORDERNOTSUBMITTED':
        return this.translocoService.translate('errorToMessagePipe.adminOrderNotSubmitted');
      case 'COLLECTIONEXPIRED':
        return this.translocoService.translate('errorToMessagePipe.collectionExpired');
      case 'DISABLEDLOCATION':
        return this.translocoService.translate('errorToMessagePipe.disabledLocation');

      default:
        if (isPostHire) {
          return this.translocoService.translate('errorToMessagePipe.defaultPost');
        }
        return this.translocoService.translate('errorToMessagePipe.defaultPre');
    }
  }
}
