import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AudioService } from '../../Services/audio.service';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'amzn-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.scss'],
})
export class AudioComponent implements OnDestroy, AfterViewInit {
  @Input()
  audioFileName: string;

  @Input()
  audioTrancriptFileName: string;

  @ViewChild('amznAudio')
  audioRef: ElementRef;

  audioImg = 'assets/images/unmute.svg';
  audioAriaLabel = this.translocoService.translate('audioComponent.audioMuteButtonAriaLabel');

  audioElement: HTMLAudioElement;
  unmuteSub: Subscription;
  muteSub: Subscription;
  unmuteAriaText: string;
  muteAriaText: string;
  private welcomeSubscription: Subscription;
  private navBeginSubscription: Subscription;

  constructor(
    private audioService: AudioService,
    private route: Router,
    private translocoService: TranslocoService,
  ) {}

  ngAfterViewInit(): void {
    this.audioElement = this.audioRef.nativeElement as HTMLAudioElement;
    this.audioElement.muted = false;
    this.navBeginSubscription = this.route.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe(() => {
        this.audioElement.pause();
        this.audioService.emitAudioFileName('');
      });

    this.welcomeSubscription = this.audioService.welcomePlay.subscribe((canPlay) => {
      if (canPlay) {
        this.replay();
      }
    });

    this.audioService.audioFileName.subscribe((af) => {
      if (af && af !== '' && !this.audioElement.muted) {
        this.replay();
      }
    });

    this.audioService.audioTrancriptFileName.subscribe((atf) => {
      if (atf && atf !== '') {
        this.audioTrancriptFileName = atf;
      }
    });

    this.unmuteSub = this.translocoService
      .selectTranslate('audioComponent.audioUnmuteButtonAriaLabel')
      .subscribe((value) => {
        this.unmuteAriaText = value;
        this.loadMuteAriaLabels();
      });

    this.muteSub = this.translocoService
      .selectTranslate('audioComponent.audioMuteButtonAriaLabel')
      .subscribe((value) => {
        this.muteAriaText = value;
        this.loadMuteAriaLabels();
      });
  }

  loadMuteAriaLabels(): void {
    if (this.audioElement.muted) {
      this.audioAriaLabel = this.unmuteAriaText;
    } else {
      this.audioAriaLabel = this.muteAriaText;
    }
  }

  toggleMute(): void {
    this.audioElement.muted = !this.audioElement.muted;
    if (this.audioElement.muted) {
      this.audioImg = 'assets/images/mute.svg';
      this.audioAriaLabel = this.unmuteAriaText;
    } else {
      this.audioImg = 'assets/images/unmute.svg';
      this.audioAriaLabel = this.muteAriaText;
    }
  }

  replay(): void {
    this.audioElement.currentTime = 0;
    this.audioElement.muted = false;
    this.audioImg = 'assets/images/unmute.svg';
    setTimeout(() => this.audioElement.play().catch((error) => console.warn('Audio Failure', error)), 50);
  }

  ngOnDestroy(): void {
    this.welcomeSubscription.unsubscribe();
    this.navBeginSubscription.unsubscribe();
    this.muteSub.unsubscribe();
    this.unmuteSub.unsubscribe();
  }
}
