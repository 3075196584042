import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AudioService } from '../../Services/audio.service';
import { DrugCollectionService } from '../../Services/drug-collection.service';
import { FaqService } from '../../Services/faq.service';
import { TrackerService } from '../../Services/tracker.service';
import { PageTemplateBlankComponent } from '../page-template-blank/page-template-blank.component';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'amzn-page-template',
  templateUrl: './page-template.component.html',
  styleUrls: ['./page-template.component.scss'],
})
export class PageTemplateComponent extends PageTemplateBlankComponent {
  @Output()
  primaryClickEvent: EventEmitter<any> = new EventEmitter();

  @Input()
  primaryText = this.translocoService.translate('pageTemplateComponent.primaryTextButton');

  @Input()
  secondaryText = this.translocoService.translate('pageTemplateComponent.secondaryTextButton');

  @Input()
  primaryDisabled?: boolean = false;

  @Input()
  category: string;

  @Input()
  nextRoute: string;

  @Input()
  backRoute: string;

  constructor(
    private router: Router,
    tracker: TrackerService,
    drugCollectionService: DrugCollectionService,
    faqService: FaqService,
    audioService: AudioService,
    private translocoService: TranslocoService,
  ) {
    super(tracker, drugCollectionService, faqService, audioService);
  }

  clickPrimaryButton(): void {
    this.primaryClickEvent.next(true);
    this.goToNext();
  }

  goToNext(): void {
    this.tracker.matomoTrackEvent(this.category, 'clicked', this.primaryText);
    this.router.navigateByUrl(this.nextRoute);
  }

  back(): void {
    this.tracker.matomoTrackEvent(this.category, 'clicked', this.secondaryText);
    this.router.navigateByUrl(this.backRoute);
  }
}
