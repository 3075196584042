import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { LocalStorageService } from './local-storage.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  private static readonly connectionRetry = 5000;
  public hubConnection: signalR.HubConnection;
  $abandoned = new Subject<string>();

  constructor(private localStorageService: LocalStorageService) {}

  public startConnection = () => {
    if (
      this.hubConnection !== undefined &&
      this.hubConnection.state !== undefined &&
      this.hubConnection.state === 'Connected'
    ) {
      return;
    }

    const inviteId = this.localStorageService.getItem('inviteId');
    if (!inviteId) {
      return;
    }

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl('/api/candidateSignalRRegistration/' + inviteId)
      .build();

    this.hubConnection.onclose(async () => {
      this.startConnection();
    });

    this.hubConnection.on('newMessage', (message: any) => {
      this.processSignalRMessage(message);
    });

    this.hubConnection
      .start()
      .then(() => console.log('SignalR Connection started'))
      .catch((err) => {
        console.error('Error while starting connection: ' + err);
        setTimeout(() => this.startConnection(), SignalRService.connectionRetry);
      });
  };

  processSignalRMessage(message: any): void {
    if (message !== undefined) {
      if (message.status !== undefined && message.status === 'abandoned') {
        if (message.step && message.step === 'info_cleanup') {
          this.$abandoned.next('abandoned');
        }
      }
    }
  }
}
