import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkflowConfig } from '../../shared/Services/config/workflow-config';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { EventBusService } from '../../shared/Services/event-bus.service';
import { LocalStorageService } from '../../shared/Services/local-storage.service';
import { SignalRService } from '../../shared/Services/signalr.service';
import { MatomoTracker } from 'ngx-matomo';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { headingDistanceTo } from 'geolocation-utils';
import { AudioService } from '../../shared/Services/audio.service';
import { DemoModeService } from '../../shared/Services/demo-mode/demo-mode.service';
import { RefreshService } from '../../shared/Services/refresh.service';
import { TrackerService } from '../../shared/Services/tracker.service';
import { DrugCollectionService } from '../../shared/Services/drug-collection.service';
import { LookupInviteRequest } from '../../shared/interfaces/lookup-invite-request.interface';
import { ErrorToMessagePipe } from '../../shared/pipes/error-to-message.pipe';
import { TranslocoService } from '@jsverse/transloco';

declare let jQuery: any;

@Component({
  selector: 'amzn-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WelcomeComponent implements OnInit, AfterViewInit {
  @ViewChild('errormodal')
  errorModal: TemplateRef<any>;
  @ViewChild('locationmodal')
  locationModal: TemplateRef<any>;
  @ViewChild('audiomodal')
  audioModal: TemplateRef<any>;

  locationModalRef: NgbModalRef;

  lookupForm: UntypedFormGroup;
  enableNextButton = true;
  locationCode: string;
  playAudio: boolean;
  errorContent: string;
  locationState: string;
  isValidDob = true;
  isValidPhoneNumber = true;
  isValidAlias = true;
  submitted = false;
  isAllFieldValid = true;
  closeResult: string;
  isPostHire = false;
  isDemo = false;
  errorMsg: string;
  companyAlias = 'Amazon Alias';
  missingLastName = false;
  missingDOB = false;
  missingAlias = false;
  missingPhoneNumber = false;

  audioModalRef: NgbModalRef;
  locationHandlerId: number;
  disableGetStarted = false;
  locCoordinates = {
    longitude: 0,
    latitude: 0,
  };

  constructor(
    private router: Router,
    private drugCollectionService: DrugCollectionService,
    private formBuilder: UntypedFormBuilder,
    private eventBus: EventBusService,
    private localStorageService: LocalStorageService,
    private signalRService: SignalRService,
    private matomoTracker: MatomoTracker,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private audioService: AudioService,
    private demoModeService: DemoModeService,
    private tracker: TrackerService,
    private errorToMessagePipe: ErrorToMessagePipe,
    private translocoService: TranslocoService,

    refreshService: RefreshService,
  ) {
    refreshService.refreshWelcomePage();
  }

  get fields(): { [key: string]: AbstractControl } {
    return this.lookupForm.controls;
  }

  get dateOfBirth(): AbstractControl {
    return this.lookupForm.get('dateOfBirth');
  }
  get day(): AbstractControl {
    return this.lookupForm.get('birthDate');
  }
  get month(): AbstractControl {
    return this.lookupForm.get('birthMonth');
  }
  get year(): AbstractControl {
    return this.lookupForm.get('birthYear');
  }
  get lastName(): AbstractControl {
    return this.lookupForm.get('lastName');
  }
  get phoneNumber(): AbstractControl {
    return this.lookupForm.get('phoneNumber');
  }
  get alias(): AbstractControl {
    return this.lookupForm.get('alias');
  }

  ngOnInit(): void {
    this.tracker.matomoTrackPageView('Sign In Form', window.location.hash.substring(1));
    const userData = this.route.snapshot.data.userData;

    const url = new URL(window.location.href).search.toLocaleLowerCase();
    if (url.indexOf('nonpre') > -1) {
      this.isPostHire = true;
    }

    if (url.indexOf('demo') > -1) {
      this.isDemo = true;
      this.companyAlias = 'Company Alias';
      this.demoModeService.demoMode = true;
      this.demoModeService.setDemeThemeCSS();
    } else {
      this.demoModeService.demoMode = false;
    }

    this.eventBus.helpContentAnnounced({
      // eslint-disable-next-line
      help_content: this.translocoService.translate('welcomeComponent.helpContent'),
      component: 'welcome',
    });

    if (userData.error) {
      jQuery('#error-modal').modal('show');
      if (userData.error.status === 404) {
        jQuery('#errorContent').html('Order Not Found');
      } else {
        const message = this.errorToMessagePipe.transform(userData.error.error, this.isPostHire);
        jQuery('#errorContent').html(message);
      }
      this.disableGetStarted = true;
    }

    this.createFormControls();
    this.localStorageService.clear();
    this.translocoService.setActiveLang('en-US');
  }

  ngAfterViewInit(): void {
    const userData = this.route.snapshot.data.userData;
    this.locationCode = userData.location;
    this.playAudio = userData.playAudio ? userData.playAudio : false;
    this.localStorageService.setItem('locationUrl', userData.locationUrl ? userData.locationUrl : '');
    this.audioService.emitPlayAudio(this.playAudio);
    if (this.isPostHire) {
      this.audioService.emitAudioFileName('welcome-alias');
    } else {
      this.audioService.emitAudioFileName('welcome-phone-number');
    }
    this.locationState = userData.locationState ? userData.locationState : '';
    if (userData.isGeoFenced) {
      this.locCoordinates.latitude = userData.latitude;
      this.locCoordinates.longitude = userData.longitude;
      this.openLocationModal();
      this.locationHandlerId = navigator.geolocation.watchPosition(
        (position) => {
          if (this.distanceFrom(position.coords, this.locCoordinates) <= 304.8) {
            this.closeLocationModal();
            navigator.geolocation.clearWatch(this.locationHandlerId);
          }
        },
        (positionError) => {
          console.error(positionError);
        },
        { timeout: 10000, enableHighAccuracy: true },
      );
    }

    this.tracker.matomoTrackEvent('auth', 'clicked', 'invite loaded');

    if (this.playAudio) {
      setTimeout(() => this.openAudioModal(), 0);
    }
  }

  distanceFrom(from, to): number {
    return headingDistanceTo(from, to).distance;
  }

  public createFormControls(): void {
    if (this.isPostHire) {
      this.lookupForm = this.formBuilder.group({
        lastName: ['', Validators.required],
        birthDate: ['', Validators.required],
        birthMonth: ['', Validators.required],
        birthYear: ['', Validators.required],
        alias: ['', Validators.required],
      });
    } else {
      this.lookupForm = this.formBuilder.group({
        lastName: ['', Validators.required],
        birthDate: ['', Validators.required],
        birthMonth: ['', Validators.required],
        birthYear: ['', Validators.required],
        phoneNumber: ['', Validators.required],
      });
    }
  }

  dateOfBirthKeyTab(event: any, maxLength: number): void {
    if (!event || !event.keyCode || event.keyCode === 9 || event.keyCode === 16) {
      return;
    }
    let nextInput = null;
    // manually set the nextInput element
    if (event.srcElement === document.getElementById('birthMonth')) {
      nextInput = document.getElementById('birthDay');
    }
    if (event.srcElement === document.getElementById('birthDay')) {
      nextInput = document.getElementById('birthYear');
    }
    // const nextInput = event.srcElement.nextElementSibling; // get the sibling element
    const target = event.target || event.srcElement;
    // check the maxLength from here
    if (target.value.length === maxLength) {
      if (nextInput == null) {
        return;
      } else {
        nextInput.focus();
      } // focus if not null
    }

    if (target.value.length > maxLength) {
      const newValue = target.value.substr(0, maxLength);
      const category = event.currentTarget.id;

      // if (category === 'birthYear')
      this.lookupForm.get(category).setValue(newValue);
    }
  }

  phoneNumberKeyPress(event: any): boolean {
    const acceptableChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Tab'];
    if (acceptableChars.includes(event.key)) {
      return true;
    }
    return false;
  }

  isValidDate(year: number, month: number, day: number): boolean {
    const dob = new Date(year, month, day);
    const today = new Date();
    if (today < dob) {
      return false;
    }
    return dob.getFullYear() === year && dob.getMonth() === month && dob.getDate() === day;
  }

  validateForm(): void {
    this.enableNextButton = true;
  }

  submit(obj): void {
    this.submitted = true;
    this.isAllFieldValid = true;
    this.isValidDob = true;

    if (this.isPostHire) {
      this.isValidAlias = true;
    } else {
      this.isValidPhoneNumber = true;
    }

    if (this.lookupForm.invalid) {
      this.isAllFieldValid = false;
      this.checkForMissingFormValues();
      return;
    }

    const date = this.lookupForm.get('birthDate').value;
    const month = this.lookupForm.get('birthMonth').value;
    const year = this.lookupForm.get('birthYear').value;
    const phoneNumber = this.lookupForm.get('phoneNumber')?.value;
    const regex = /^[0-9]+$/;
    const alias = this.lookupForm.get('alias')?.value;
    const aliasRegex = /^[a-zA-Z]{1,25}$/;
    // const email = this.lookupForm.get('email')?.value;
    // const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!this.isValidDate(year, month - 1, date) || year.toString().length > 4) {
      this.isValidDob = false;
      return;
    }

    if (!this.isPostHire) {
      if (phoneNumber) {
        if (phoneNumber.length !== 4 || !phoneNumber.match(regex)) {
          this.isValidPhoneNumber = false;
          this.lookupForm.get('phoneNumber').setErrors({ invalidphoneNumber: true });
          return;
        }
      }
    }

    if (this.isPostHire) {
      if (alias) {
        if (!alias.match(aliasRegex)) {
          this.isValidAlias = false;
          this.lookupForm.get('alias').setErrors({ invalidAlias: true });
          return;
        }
      }
    }

    const dob = year + '-' + (month < 10 ? '0' + month : month) + '-' + (date < 10 ? '0' + date : date);

    this.showPleaseWait(true);
    this.tracker.matomoTrackEvent('auth', 'clicked', 'candidate details provided');

    let body: LookupInviteRequest;

    if (this.isPostHire) {
      body = {
        location: this.locationCode,
        lastName: this.trim(obj.lastName),
        dateOfBirth: dob,
        alias,
        isPostHire: this.isPostHire,
      };
    } else {
      body = {
        location: this.locationCode,
        lastName: this.trim(obj.lastName),
        lastFourPhoneNumber: phoneNumber ? phoneNumber : null,
        dateOfBirth: dob,
        isPostHire: this.isPostHire,
      };
    }
    this.redirectToNextPage(body);
  }

  redirectToNextPage(body: LookupInviteRequest): void {
    this.drugCollectionService.lookupInvite(body).subscribe({
      next: (res) => {
        const inviteId = this.localStorageService.getItem('inviteId');
        if (res.selectedTranslationLanguage) {
          this.translocoService.setActiveLang(res.selectedTranslationLanguage);
        }
        if (inviteId != null && inviteId !== res.inviteId) {
          this.localStorageService.clear();
          this.demoModeService.demoMode = this.isDemo;
        }
        this.localStorageService.setItem('location_code', this.locationCode);
        this.localStorageService.setItem('locationState', this.locationState);
        this.localStorageService.setItem('last_name', this.trim(body.lastName)?.toUpperCase());
        this.localStorageService.setItem('inviteId', res.inviteId);
        this.localStorageService.setItem('first_name', res.firstName);
        this.localStorageService.setItem('phone_number', res.phoneNumber);
        this.localStorageService.setItem('address_line_1', res.address.line1);
        this.localStorageService.setItem('address_line_2', res.address.line2);
        this.localStorageService.setItem('city', res.address.city);
        this.localStorageService.setItem('state', res.address.state);
        this.localStorageService.setItem('zip', res.address.zip);
        this.localStorageService.setItem('deviceType', res.deviceType);
        this.localStorageService.setItem('emailAddress', res.emailAddress);
        this.localStorageService.setItem('alias', body.alias);
        this.localStorageService.setItem('reasonForTest', res.reasonForTest);
        this.localStorageService.setItem('includesAlcohol', res.includesAlcohol);
        this.localStorageService.setItem('playAudio', this.playAudio);
        this.localStorageService.setItem('shouldSkipAlcohol', res.shouldSkipAlcohol);
        this.localStorageService.setItem('date_of_birth', body.dateOfBirth);
        this.localStorageService.setItem('shouldSkipEvaluation', res.shouldSkipEvaluation);
        this.showPleaseWait(false);

        const activeLang = this.translocoService.getActiveLang();
        if (activeLang !== 'en-US') {
          this.drugCollectionService.updateSelectedTranslationLanguage(res.inviteId, activeLang).subscribe({
            next: () => {
              this.localStorageService.setItem('selectedTranslationLanguage', activeLang);
            },
            error: (error) => {
              console.error(error);
            },
          });
        }

        if (res.deviceType.toLocaleLowerCase() === 'oraleze') {
          this.localStorageService.setItem('playAudio', 'false');
          this.audioService.emitPlayAudio(false);
        }
        if (
          res.status === 'negative' ||
          res.status === 'abandoned' ||
          (res.status === 'confirmation' && (res.step === 'thankyou' || res.step === 'info_cleanup'))
        ) {
          if (res.status === 'abandoned') {
            console.error('The order has been abandoned.', ['welcome : lookup invite call'], ['server']);
          } else {
            console.error(
              'Already completed drug screen and results have been submitted',
              ['welcome : lookup invite call'],
              ['server'],
            );
          }
          this.openErrorModal(res.status);
        } else {
          // start signalr messaging
          this.startSignalrMessaging();
          this.setMatomoCustomVariables();

          const photoSteps: Array<string> = [
            'info_capture_image',
            'capture_image',
            'confirm_image',
            'upload_image',
            'image_processing',
            'info_test_id_capture',
            'info_test_kit_pouch_info_capture',
            'shipit_capture_image',
            'shipit_upload_image',
            'shipit_photo_rules_help',
            'shipit_confirm_image',
            'shipit_confirm_barcode_capture',
            'shipit_oraleze_photo_rules_help',
            'shipit_oraleze_capture_image',
            'shipit_oraleze_confirm_image',
            'shipit_oraleze_upload_image',
            'info_oraleze_open_pouch',
            'info_oraleze_sample_collection',
            'info_oraleze_insert_collection_wand',
            'info_oraleze_close_cap',
            'shipit_oraleze_package_testkit_help',
            'shipit_oraleze_photo_rules_help',
            'shipit_oraleze_capture_image',
            'shipit_oraleze_confirm_image',
            'shipit_oraleze_upload_image',
            'shipit_capture_initials_image',
            'shipit_confirm_initials_image',
            'shipit_upload_initials_image',
            'shipit_oraleze_upload_initials_image',
            'shipit_oraleze_confirm_initials_image',
            'shipit_oraleze_capture_initials_image',
            'shipit_photo_rules_initials_help',
            'shipit_oraleze_photo_rules_initials_help',
          ];

          // if step is welcome, navigate to /review-candidate
          // if step is photo related, navigate to photo-rules page
          // for all other scenarios, navigate to the link defined in WorkflowConfig for the particular step
          if (res.step) {
            if (res.step === 'welcome' || res.step === 'station') {
              if (res.requiresStation) {
                this.router.navigateByUrl('/station');
              } else {
                this.updateStep();
                this.router.navigateByUrl('/review-candidate');
              }
            } else if (res.step === 'agreement_esignature_consent') {
              this.router.navigateByUrl('/agreement/e-signature-consent');
            } else if (res.step === 'agreement_amazon_drug_test_policy') {
              this.router.navigateByUrl('/agreement/drug-policy');
            } else if (res.step === 'agreement_fair_credit_reporting_act') {
              this.router.navigateByUrl('/agreement/fair-credit');
            } else if (res.step === 'agreement_california_consumer_reports') {
              this.router.navigateByUrl('/agreement/ca-consumer-reports');
            } else if (res.step === 'agreement_additional_disclosures_and_acknowledgements') {
              this.router.navigateByUrl('/agreement/additional-disclosures');
            } else if (res.step === 'agreement_acknowledgments_authorization') {
              this.router.navigateByUrl('/agreement/acknowledgments-authorization');
            } else if (res.step === 'nc_consent') {
              this.router.navigateByUrl('/agreement/nc-consent');
            } else if (res.step === 'wait_for_admin' || res.status === 'adjudicate') {
              this.router.navigateByUrl('/admin-result/wait');
            } else if (res.step === 'mro_advance_notification') {
              this.router.navigateByUrl('/admin-result/mro-advance-notification');
            } else if (photoSteps.includes(res.step)) {
              const deviceType: string = this.localStorageService.getItem('deviceType');
              if (res.step.includes('initials')) {
                this.router.navigateByUrl('/admin-result/photo-rules-initials');
              } else if (deviceType.toLocaleLowerCase() === 'oraleze') {
                this.router.navigateByUrl('/oraleze/photo-instruction');
              } else if (res.step.includes('shipit')) {
                this.router.navigateByUrl('/admin-result/photo-rules');
              } else {
                this.router.navigateByUrl('/instruction/photo-help');
              }
            } else {
              if (
                this.localStorageService.getItem('deviceType').toLocaleLowerCase() === 'oraltox' &&
                res.step === 'shipit_package_testkit_help'
              ) {
                res.step = 'info_cleanup';
              }
              this.router.navigateByUrl(WorkflowConfig.STEPS[res.step].link);
            }
          }
        }
      },
      error: (response) => {
        this.showPleaseWait(false);
        const errorMessage = this.errorToMessagePipe.transform(response?.error, this.isPostHire);
        jQuery('#errorContent').html(errorMessage);
        jQuery('#error-modal').modal('show');
      },
    });
  }

  startSignalrMessaging(): void {
    this.signalRService.startConnection();
    // No need to subscribe to message here for now.
  }

  showPleaseWait(flag: boolean): void {
    if (flag) {
      jQuery('#please-wait-modal').modal('show');
    } else {
      jQuery('#please-wait-modal').modal('hide');
      jQuery('.modal-backdrop').remove();
    }
  }

  updateStep(): void {
    this.drugCollectionService.postStatusCall('welcome').subscribe({
      next: () => {},
      error: (error) => {
        console.error(error.errorMessage, ['welcome : update step call'], ['server']);
      },
    });
  }

  setMatomoCustomVariables(): void {
    const applicantId = this.localStorageService.getItem('inviteId');
    const lastName = this.localStorageService.getItem('last_name');

    this.matomoTracker.setUserId(applicantId);

    this.matomoTracker.setCustomVariable(1, 'Applicant Id', applicantId, 'visit');
    this.matomoTracker.setCustomVariable(2, 'Last Name', lastName, 'visit');
  }

  openErrorModal(status): void {
    if (status === 'abandoned') {
      this.errorContent = this.translocoService.translate('welcomeComponent.abandonErrorContent');
    } else {
      this.errorContent =
        this.translocoService.translate('welcomeComponent.defaultErrorContent1') +
        this.translocoService.translate('welcomeComponent.defaultErrorContent2');
    }
    this.modalService.open(this.errorModal, { windowClass: 'getErrorModal', ariaLabelledBy: 'errorModal' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      () => {
        this.closeResult = `Dismissed `;
      },
    );
  }

  openLocationModal(): void {
    this.locationModalRef = this.modalService.open(this.locationModal, {
      windowClass: 'getErrorModal',
      ariaLabelledBy: 'locationModal',
      backdrop: 'static',
      keyboard: false,
    });
    this.locationModalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      () => {
        this.closeResult = `Dismissed `;
      },
    );
  }

  closeLocationModal(): void {
    this.locationModalRef.close();
  }

  openAudioModal(): void {
    this.audioModalRef = this.modalService.open(this.audioModal, {
      windowClass: 'audioModal',
      ariaLabelledBy: 'audioModal',
      backdrop: 'static',
      keyboard: false,
    });
    this.audioModalRef.result.then(() => this.audioService.emitPlayForWelcome());
  }

  trim(value: string): string {
    return value?.trim();
  }

  checkForMissingFormValues(): void {
    const lastName = this.lookupForm.get('lastName')?.value;
    const date = this.lookupForm.get('birthDate').value;
    const month = this.lookupForm.get('birthMonth').value;
    const year = this.lookupForm.get('birthYear').value;
    const phoneNumber = this.lookupForm.get('phoneNumber')?.value;
    const alias = this.lookupForm.get('alias')?.value;

    this.missingLastName = !lastName;
    this.missingDOB = !date || !month || !year;
    if (!this.isPostHire) {
      this.missingPhoneNumber = !phoneNumber;
    }
    if (this.isPostHire) {
      this.missingAlias = !alias;
    }
  }
}
