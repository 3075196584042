<div class="sign-container" (window:resize)="resize()">
  <div style="display: none">
    <img #signatureImg id="signatureImg" src="" class="img-responsive" />
  </div>
  <h2 class="signature_body_title mb-0">
    <!--* You can use a tablet, mouse or touch (smartphones & tablets).-->
    {{ padNote }}
  </h2>

  <div class="canvas_bg" id="canvas_bg" aria-role="region" aria-label="Signature Pad for you to draw your signature on">
    <signature-pad
      id="sigPad"
      #signaturePad
      (onBeginEvent)="drawBegin()"
      aria-role="region"
      aria-label="Add your signature above the line"
      (onEndEvent)="drawEnd()"
    ></signature-pad>
  </div>
  <div class="row canvas_name">
    <div class="col-1 icon">
      <button class="fa fa-times clear_signature_button" tabindex="0" role="button" aria-label="Remove Signature" (click)="clear()">X</button>
    </div>
    <div class="col-10 line">&nbsp;</div>
  </div>
  <br />
</div>
