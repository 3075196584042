import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { DrugCollectionService } from '../../shared/Services/drug-collection.service';
import { catchError, map } from 'rxjs/operators';
import { LocalStorageService } from '../../shared/Services/local-storage.service';
import { LookupOrderRequest } from '../../shared/interfaces/lookup-order-request.interface';

@Injectable({
  providedIn: 'root',
})
export class WelcomeDataResolverService {
  constructor(
    private drugCollectionService: DrugCollectionService,
    private localStorageService: LocalStorageService,
  ) {}

  resolve(): Observable<any> | Observable<Observable<any>> | Promise<Observable<any>> {
    const url = new URL(window.location.href);
    this.localStorageService.setItem('locationUrl', window.location.href);
    let orderId = url.searchParams.get('OrderId');
    if (orderId) {
      sessionStorage.setItem('orderId', url.searchParams.get('OrderId'));
    } else {
      orderId = sessionStorage.getItem('orderId');
    }
    if (orderId) {
      const body: LookupOrderRequest = {
        encryptedOrderId: orderId,
      };

      return this.drugCollectionService.lookupOrder(body).pipe(
        catchError((error) => {
          sessionStorage.removeItem('orderId');
          return of({ error });
        }),
      );
    } else {
      const url2 = new URL(window.location.href).search.toUpperCase();
      let locationId = url2.substring(url2.indexOf('LOCATION=') + 9);
      if (!locationId) {
        locationId = this.localStorageService.getItem('locationUrl').split('?location=')[1];
      }
      return this.drugCollectionService.getLocation(locationId).pipe(
        map((locationData) => {
          const locationUrl = url.searchParams.get('LOCATION')
            ? url.href
            : this.localStorageService.getItem('locationUrl');

          return {
            id: '',
            location: locationData.id,
            requestStation: true,
            latitude: locationData.latitude,
            longitude: locationData.longitude,
            isGeoFenced: locationData.isGeoFenced,
            playAudio: locationData.playAudio,
            locationState: locationData.address.state,
            locationUrl,
          };
        }),
        catchError((error) => of({ error })),
      );
    }
  }
}
