import { Injectable } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class DemoModeService {
  updatedWaitScreenVideo = '../assets/media/First-Advantage-Collaborate-Conference-2019.mp4';
  constructor(private localStorage: LocalStorageService) {}

  get demoMode(): boolean {
    return this.localStorage.getItem('demoMode');
  }

  set demoMode(value: boolean) {
    this.localStorage.setItem('demoMode', value);
  }

  setDemeThemeCSS(): void {
    //logo
    document.body.style.setProperty('--logo', "url('../assets/images/FADV/FA_FullLogo_Horiz_White_RGB.png')");
    document.body.style.setProperty('--logo-header-max-width', '200px');
    //headers
    document.body.style.setProperty('--main-header-background', '#002F87');
    document.body.style.setProperty(
      '--content-header-background',
      'linear-gradient(90deg, rgba(73, 197, 225, 1) 0%, rgba(62, 130, 200, 1) 100%)',
    );
    document.body.style.setProperty('--content-header-text-color', '#ffffff');
    document.body.style.setProperty('--help-content-color', '#ffffff');
    document.body.style.setProperty('--help-icon-color', '#ffffff');
    // buttons
    document.body.style.setProperty(
      '--btn-active-background-image',
      'linear-gradient(90deg, #49C5E1 0%, #3E82C8 100%)',
    );
    document.body.style.setProperty('--btn-active-box-shadow', 'none');
    document.body.style.setProperty('--button-border', '0px');
    document.body.style.setProperty('--btn-active-text-color', 'white');
    document.body.style.setProperty('--button-text-color', '#4298D1');
    document.body.style.setProperty('--button-box-shadow', 'none');
    document.body.style.setProperty('--button-box-shadow2', 'none');
    document.body.style.setProperty('--button-background-image', 'white');
    document.body.style.setProperty('--button-border', '1px solid');
    document.body.style.setProperty(
      '--button-border-image-source',
      'linear-gradient(90deg, #49C5E1 0%, #3E82C8 100.%)',
    );
    document.body.style.setProperty('--button-border-radius', '50px');
    //loading spinner
    document.body.style.setProperty('--loader-border-color', '#49C5E1 #3E82C8');
    //favicon
    const favicon = document.getElementById('favicon');
    if (favicon) {
      favicon.setAttribute('href', '../assets/images/fadv/FA-Favicon-32x32.png');
    }
  }
}
