import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FaqService {
  faqHtml: BehaviorSubject<string> = new BehaviorSubject<string>('');

  setFaqHtml(faqHtml: string): void {
    this.faqHtml.next(faqHtml);
  }
}
