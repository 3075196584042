<amzn-page-template-blank
  *transloco="let t; prefix: 'thankyouComponent'"
  title="{{ t('thankyouBanner') }}"
  category="thankyou"
  step="thankyou"
  (statusUpdate)="onLoad()"
>
  <div class="row m-0 mt-4">
    <div class="col-12 text-center thankyouContent">
      <h2>{{ t('thankYouText') }}</h2>
      <p class="mt-5">{{ t('amazonLogoAltText') }}</p>
    </div>
  </div>
</amzn-page-template-blank>
