import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';

@Component({
  selector: 'amzn-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss'],
})
export class SignaturePadComponent implements AfterViewInit {
  @Input()
  displayLabels: any;
  @Input()
  padNote: any;
  @Output()
  drawComplete: EventEmitter<any> = new EventEmitter();

  @ViewChild('signaturePad')
  private signaturePad: SignaturePad;
  @ViewChild('signatureImg')
  private signatureImg: ElementRef;

  private signature: any = null;

  public ngAfterViewInit(): void {
    this.resize();
  }

  // set the dimensions of the signature pad canvas
  public resize(): void {
    const canvasBg: any = document.getElementById('canvas_bg');
    const canvas: any = document.getElementById('sigPad').firstChild;
    this.size(canvasBg, canvas);
  }

  public size(container: any, canvas: any): void {
    let canvsWidthNew = window.screen.width;
    const ctx = canvas.getContext('2d');
    if (canvsWidthNew <= 450) {
      canvsWidthNew = canvsWidthNew - 60;
      ctx.canvas.width = canvsWidthNew;
    } else {
      if (ctx.canvas.width === 0 || ctx.canvas.width === '') {
        ctx.canvas.width = 566;
      } else {
        ctx.canvas.width = container.clientWidth;
      }
    }

    if (this.signature != null) {
      this.setSignature(this.signature);
      this.initializeImage();
    }
  }

  // public setOptions() {
  //   this.signaturePad.set('penColor', 'rgb(0, 0, 0)');
  // }

  public setPenColor(color: any): void {
    this.signaturePad.set('penColor', color);
  }

  public getSignature(): any {
    return this.signature;
  }

  public setSignature(value: any): void {
    if (value == null || value === undefined || value === '') {
      this.signature = '';
    } else {
      this.signature = value;
    }

    const img = this.signatureImg.nativeElement;
    img.src = value !== undefined ? value : '';

    setTimeout(() => {
      this.initializeImage();
    }, 10);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  drawBegin(): void {}

  drawEnd(): void {
    this.signature = this.signaturePad.toDataURL();

    const obj = {};
    this.drawComplete.emit(obj);
  }

  initializeImage(): void {
    const canvas1: any = document.getElementById('sigPad').firstChild;
    const img = this.signatureImg.nativeElement;
    const ctx1 = canvas1.getContext('2d');
    ctx1.drawImage(img, 0, 0, img.width, img.height);
  }

  public clear(): void {
    this.signaturePad.clear();
    this.signature = '';
    const obj = {};
    this.drawComplete.emit(obj);
  }
}
