import { Component, OnInit } from '@angular/core';
import { EventBusService } from '../../shared/Services/event-bus.service';
import { LocalStorageService } from '../../shared/Services/local-storage.service';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'amzn-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss'],
})
export class ThankyouComponent implements OnInit {
  constructor(
    private eventBus: EventBusService,
    private localStorageService: LocalStorageService,
    private translocoService: TranslocoService,
  ) {}
  ngOnInit(): void {
    this.eventBus.helpContentAnnounced({
      // eslint-disable-next-line
      help_content: this.translocoService.translate('thankyouComponent.helpContent'), component: 'thank-you',
      hideHelpButton: true,
    });
  }

  onLoad(): void {
    this.localStorageService.clear();
  }
}
