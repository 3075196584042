<div *transloco="let t; prefix: 'welcomeComponent'" class="container-fluid" [formGroup]="lookupForm">
  <amzn-title>{{ t('welcomeBanner') }}</amzn-title>
  <div class="anime-clean contentArea">
    <div *ngIf="isPostHire" id="postHireAnnouncement" class="col-12 mt-3">
      <strong>
        {{ t('postempGetStartedInstruction') }}
      </strong>
    </div>
    <div class="row m-0 mt-4">
      <div class="form-group col-12">
        <label for="lastName">{{ t('lastNameForm') }} <span class="req" title="required">*</span></label>
        <input
          aria-required="true"
          type="text"
          class="form-control"
          id="lastName"
          placeholder="{{ t('lastNamePlaceholder') }}"
          formControlName="lastName"
          [ngClass]="{ 'is-invalid': submitted && fields.lastName.errors }"
        />
      </div>
    </div>
    <div class="row m-0 mt-4">
      <div class="form-group col-12 mb-0">
        <fieldset>
          <legend class="mb-0 birthdate-legend-text">{{ t('birthDateForm') }}</legend>
          <div class="row m-0">
            <div class="col-2 mr-3 pl-0">
              <label for="birthMonth" class="birthdate-label-text">{{ t('birthMonthForm') }} <span class="req" title="required">*</span></label>
              <input
                aria-required="true"
                type="number"
                class="form-control text-center birthdate-input"
                pattern="\d*"
                id="birthMonth"
                formControlName="birthMonth"
                placeholder="{{ t('birthMonthPlaceholder') }}"
                min="0"
                max="12"
                minlength="2"
                maxlength="2"
                (keyup)="dateOfBirthKeyTab($event, 2)"
                [ngClass]="{
                  'date-invalid': submitted && fields.birthMonth.errors
                }"
              />
            </div>
            <div class="col-2 mr-3 pl-0">
              <label for="birthDay" class="birthdate-label-text">{{ t('birthDayForm') }} <span class="req" title="required">*</span></label>
              <input
                aria-required="true"
                type="number"
                class="form-control text-center birthdate-input"
                pattern="\d*"
                id="birthDay"
                formControlName="birthDate"
                placeholder="{{ t('birthDayPlaceholder') }}"
                min="0"
                max="31"
                minlength="2"
                maxlength="2"
                (keyup)="dateOfBirthKeyTab($event, 2)"
                [ngClass]="{ 'date-invalid': submitted && fields.birthDate.errors }"
              />
            </div>
            <div class="col-2 mr-3 pl-0">
              <label for="birthYear" class="birthdate-label-text">{{ t('birthYearForm') }} <span class="req" title="required">*</span></label>
              <input
                aria-required="true"
                type="number"
                class="form-control text-center birthdate-input"
                pattern="\d*"
                id="birthYear"
                formControlName="birthYear"
                placeholder="{{ t('birthYearPlaceholder') }}"
                min="0"
                minlength="4"
                maxlength="4"
                (keyup)="dateOfBirthKeyTab($event, 4)"
                [ngClass]="{ 'date-invalid': submitted && fields.birthYear.errors }"
              />
            </div>
          </div>
          <div class="row m-0"></div>
        </fieldset>
      </div>
    </div>
    <div class="row m-0 mt-4">
      <div class="form-group">
        <div *ngIf="!isPostHire" class="col-12">
          <label for="phoneNumber">{{ t('last4PhoneNumberForm') }}<span class="req" title="required">*</span></label>
        </div>
        <div *ngIf="!isPostHire" class="col-6">
          <input
            aria-required="true"
            type="text"
            inputmode="tel"
            maxlength="4"
            class="form-control"
            id="phoneNumber"
            placeholder="0000"
            formControlName="phoneNumber"
            (keydown)="phoneNumberKeyPress($event)"
            [ngClass]="{ 'is-invalid': submitted && fields.phoneNumber.errors && !isPostHire }"
          />
        </div>
        <div *ngIf="isPostHire" class="row m-0">
          <div class="form-group col-12">
            <label for="alias" class="alias-text">{{ t('amazonAliasForm', { companyName: 'Amazon Alias' }) }}<span class="req" title="required">*</span></label>
            <input
              aria-required="true"
              type="text"
              class="form-control"
              id="alias"
              placeholder="{{ t('amazonAliasPlaceholder') }}"
              formControlName="alias"
              [ngClass]="{ 'is-invalid': submitted && fields.alias.errors && isPostHire }"
            />
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!isValidPhoneNumber" class="row m-0 mt-4">
      <div class="form-group col-12 text-center">
        <label class="text-danger" role="alert">{{ t('last4PhoneNumberInvalidError') }}</label>
      </div>
    </div>
    <div *ngIf="!isValidAlias" class="row m-0 mt-4">
      <div class="form-group col-12 text-center">
        <label class="text-danger" role="alert">{{ t('amazonAliasInvalidError') }}</label>
      </div>
    </div>
    <div *ngIf="!isAllFieldValid" class="row m-0">
      <div class="form-group col-12 text-center">
        <div *ngIf="missingLastName">
          <label class="text-danger" role="alert">{{ t('lastNameRequiredError') }}</label>
        </div>
        <div *ngIf="missingDOB">
          <label class="text-danger" role="alert">{{ t('birthDateRequiredError') }}</label>
        </div>
        <div *ngIf="missingAlias">
          <label class="text-danger" role="alert">{{ t('amazonAliasRequiredError') }}</label>
        </div>
        <div *ngIf="missingPhoneNumber">
          <label class="text-danger" role="alert">{{ t('last4PhoneNumberRequiredError') }}</label>
        </div>
      </div>
    </div>
    <div *ngIf="!isValidDob" class="row m-0 mt-4">
      <div class="form-group col-12 text-center">
        <label class="text-danger" role="alert">{{ t('BirthDateInvalidError') }}</label>
      </div>
    </div>
    <div class="row btnAlign">
      <div class="col-12 m-b-40 mx-auto mt-5">
        <button type="button" data-testid="getStarted" class="btn button btnActive" (click)="submit(lookupForm.value)" [disabled]="disableGetStarted">
          {{ t('getStartedButton') }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Error Modal Popup Start -->
<ng-template *transloco="let t; prefix: 'welcomeComponent'" #errormodal let-modal>
  <div id="errorModal" hidden>{{ t('errorModalAriaLabel') }}</div>
  <div class="modal-header"></div>
  <div class="modal-body text-center">
    <h2 class="mt-5">{{ t('errorInstruction') }}</h2>
    <p id="errorContent">{{ errorContent }}</p>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-12 mx-auto">
        <button type="button" class="btn button btnActive" (click)="modal.close('Close click')">{{ t('closeButton') }}</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template *transloco="let t; prefix: 'welcomeComponent'" #locationmodal>
  <div id="locationModal" hidden>{{ t('locationModalAriaLabel') }}</div>
  <div class="modal-header"></div>
  <div class="modal-body text-center">
    <p id="errorContent">{{ t('locationInstruction') }}</p>
    <div class="spinner-border" role="status">
      <span class="sr-only">{{ t('loading') }}</span>
    </div>
  </div>
</ng-template>

<ng-template *transloco="let t; prefix: 'welcomeComponent'" #audiomodal let-audiomodal>
  <div id="audioModal" hidden>{{ t('audioModalAriaLabel') }}</div>
  <div class="modal-body text-center" (click)="audiomodal.close()">
    <p>{{ t('audioModalInstruction') }}</p>
    <div>
      <img height="100px" width="100px" src="assets/images/play.svg" />
    </div>
  </div>
</ng-template>
