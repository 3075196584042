import { APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import type { I18NextLoadResult, ITranslationService } from 'angular-i18next';
import { I18NEXT_SERVICE, defaultInterpolationFormat, I18NextModule } from 'angular-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

export function appInit(i18next: ITranslationService): () => Promise<I18NextLoadResult> {
  return () =>
    i18next
      .use(LanguageDetector)
      .use(HttpApi)
      .init({
        supportedLngs: ['en'],
        fallbackLng: 'en',
        debug: true,
        returnEmptyString: false,
        defaultNS: 'components',
        ns: ['components'],
        interpolation: {
          format: I18NextModule.interpolationFormat(defaultInterpolationFormat),
        },
        backend: {
          loadPath: '/assets/locales/{{lng}}/{{ns}}.json',
        },
        detection: {
          order: ['querystring', 'cookie'],
          lookupCookie: 'lang',
          lookupQuerystring: 'lng',
          caches: ['localStorage', 'cookie'],
          cookieMinutes: 1440,
        },
      });
}

export function localeIdFactory(i18next: ITranslationService): string {
  return i18next.language;
}

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE],
    multi: true,
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory,
  },
];
